import Api, { List } from ".";
import { dateRange } from "../../components/query-builder/date-picker";

interface Users extends List {
  filter?: { [key: string]: any };
  merchantId?: string;
  mobile?: string;
}

interface User {
  id: string;
}
export interface UserResponse {
  id: string;
  name: string;
  mobile: string;
  email: string;
  is_active: boolean;
  created_at: string;
}
interface NewUser {
  name: string;
  mobile: string;
  email: string;
  merchantId: string;
}
export const listUsersApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchantId,
  mobile,
}: Users) => {
  try {
    const response = await Api({
      method: "get",
      url: "users/",
      params: {
        limit,
        page,
        ...filter,
        merchant_id: merchantId,
        mobile,
      },
    });
    return response.data as { pages: number; users: UserResponse[] };
  } catch (e: any) {
    throw e;
  }
};
export const findUserApi = async ({ id }: User) => {
  try {
    const response = await Api({
      method: "get",
      url: "users/" + id,
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const createUserApi = async ({
  name,
  email,
  mobile,
  merchantId,
}: NewUser) => {
  try {
    const response = await Api({
      method: "post",
      url: "users/",
      data: {
        name,
        mobile: "+966" + mobile,
        email,
        merchant_id: merchantId,
      },
    });
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const findUserByMobileApi = async ({ mobile }: { mobile: string }) => {
  try {
    const response = await Api({
      method: "get",
      url: "users/" + mobile,
    });
    return response.data as {
      users: UserResponse[];
    };
  } catch (e) {
    throw e;
  }
};
