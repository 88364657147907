import jsonexport from "jsonexport/dist";
import { useTranslation } from "react-i18next";
import { Button } from "../../lib";

const scheme = {
  P1: "MADA",
  MC: "Mastercard",
  VC: "Visa Credit",
  AX: "American Express",
  DM: "Maestro",
  GN: "GCCNET",
};

var flatten = (function (isArray, wrapped) {
  return function (table: any) {
    return reduce("", {}, table);
  };

  function reduce(path: any, accumulator: any, table: any) {
    if (isArray(table)) {
      var length = table.length;

      if (length) {
        var index = 0;

        while (index < length) {
          var property = path + "[" + index + "]",
            item = table[index++];
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      } else accumulator[path] = table;
    } else {
      var empty = true;

      if (path) {
        for (var property in table) {
          var item = table[property],
            property = path + "." + property,
            empty = false;
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      } else {
        for (var property in table) {
          var item = table[property],
            empty = false;
          if (wrapped(item) !== item) accumulator[property] = item;
          else reduce(property, accumulator, item);
        }
      }

      if (empty) accumulator[path] = table;
    }

    return accumulator;
  }
})(Array.isArray, Object);

const DownloadReconciliationsButton = ({
  reconciliations,
  classNames,
}: any) => {
  const { t } = useTranslation();
  const download = () => {
    let reconciliationsFlattend = reconciliations.map((i: any) => flatten(i));
    let output: string[] = [];
    jsonexport(
      reconciliationsFlattend,
      { fillGaps: false, fillTopRow: false },
      (err: Error, csv: string) => {
        if (err) return console.log(err);
        output.push(csv, ",");
      }
    );
    const outFile = new File(
      output,
      "Reconciliations-" + new Date().toDateString() + ".csv"
    );
    const link = document.createElement("a");
    const url = URL.createObjectURL(outFile);
    link.href = url;
    link.download = outFile.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={() => download()}
      colorScheme={"skyblue"}
      label={t("downloadReconciliations") as string}
      classNames={classNames}
    />
  );
};

export default DownloadReconciliationsButton;
