import Api, { List } from "./";
import { dateRange } from "../../components/query-builder/date-picker";
import { ReconciliationResponse } from "../../types/reconciliations";
interface Reconciliations extends List {
  filter: { [key: string]: any };
  merchantId?: string;
  terminal_id?: string;
  queryParams: { [key: string]: any };
  dateRange?: dateRange;
}

interface Reconcile {
  id: string;
}

interface NewReconcile {
  terminal_ids: string[];
}

export const findReconiliationApi = async ({ id }: Reconcile) => {
  try {
    const response = await Api({
      method: "get",
      url: "reconciliations/" + id,
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listReconciliationsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchantId,
  terminal_id,
  queryParams,
  dateRange,
}: Reconciliations) => {
  let encodedQueryParams = btoa(JSON.stringify(queryParams));
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await Api({
      method: "get",
      url: "reconciliations/",
      params: {
        limit,
        page,
        ...filter,
        merchant_id: merchantId,
        terminal_id,
        queryParams: encodedQueryParams,
        dateRange: encodeddateRange,
      },
    });
    return response.data as {
      pages: any;
      receipts: ReconciliationResponse;
    };
  } catch (e: any) {
    throw e;
  }
};
