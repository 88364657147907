import { FC, useContext } from "react";
import { Routes as RoutesContainer, Route } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import Container from "../../layout/container";
import PublicContainer from "../../layout/public-container";
import Login from "../../pages/Login";
import Home from "../../pages/Home";
import Redirect from "../../pages/Redirect";
import { MasterDataContextProvider } from "../../contexts/MasterDataContext";
import Transactions from "../../pages/Transactions";
import Merchants from "../../pages/Merchants";
import Merchant from "../../pages/Merchant";
import Terminals from "../../pages/Terminals";
import TRSMs from "../../pages/TRSMs";
import Users from "../../pages/Users";
import Admins from "../../pages/Admins";
import Reconciliation from "../../pages/Reconciliation";
import Reconciliations from "../../pages/Reconciliations";
import Terminal from "../../pages/Terminal";
import Transaction from "../../pages/Transaction";
import ResetPassword from "../../pages/ResetPassword";
import UpdatePassword from "../../pages/UpdatePassword";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ScrollToTop } from "./scroll-to-top";
import ResetPasswordMessageSent from "../../pages/ResetPasswordMessageSent";

const Routes: FC = ({}) => {
  const { hasToken } = useContext(UserContext) as UserContextType;
  return (
    <ScrollToTop>
      {hasToken() ? (
        <Container>
          <MasterDataContextProvider>
            <RoutesContainer>
              <Route path="/" element={<Home />} />
              <Route path="/merchants">
                <Route index element={<Merchants />} />
                <Route path=":id" element={<Merchant />} />
              </Route>
              <Route path="transactions">
                <Route index element={<Transactions />} />
                <Route path=":id" element={<Transaction />} />
              </Route>
              <Route path="terminals">
                <Route index element={<Terminals />} />
                <Route path=":id" element={<Terminal />} />
              </Route>
              <Route path="/trsms" element={<TRSMs />} />
              <Route path="reconciliations">
                <Route index element={<Reconciliations />} />
                <Route path=":id" element={<Reconciliation />} />
              </Route>
              <Route path="/users">
                <Route index element={<Users />} />
              </Route>
              <Route path="/admins">
                <Route index element={<Admins />} />
              </Route>
            </RoutesContainer>
          </MasterDataContextProvider>
        </Container>
      ) : (
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfETr0hAAAAAGhTpXh9z0SiBBe2Qcm3VUawtVCq"
          language="en"
          scriptProps={{
            async: true,
            defer: false,
            appendTo: "head",
          }}
        >
          <PublicContainer>
            <RoutesContainer>
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/update-password" element={<UpdatePassword />} />
              <Route
                path="/message-sent"
                element={<ResetPasswordMessageSent />}
              />
              <Route path="*" element={<Redirect />} />
            </RoutesContainer>
          </PublicContainer>
        </GoogleReCaptchaProvider>
      )}
    </ScrollToTop>
  );
};

export default Routes;
