import Api, { List } from ".";
import Merchant from "../../pages/Merchant";
import { dateRange } from "../../components/query-builder/date-picker";
export enum MerchantStatus {
  active = "active",
  deactive = "deactive",
}

export interface MerchantResponse {
  id: string;
  name: string;
  name_ar: string;
  merchant_bank_id: string;
  status: "active" | "deactive";
  created_at: string;
}
interface Merchants extends List {
  filter?: { [key: string]: any };
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface Merchant {
  id?: string;
  name?: string;
  name_ar?: string;
}

interface UpdateMerchant {
  id: string;
  status?: MerchantStatus;
  name?: string;
  name_ar?: string;
}

export const listMerchantsApi = async ({
  limit = 30,
  page = 1,
  filter,
  queryParams,
  dateRange,
}: Merchants) => {
  try {
    const response = await Api({
      method: "get",
      url: "merchants/",
      params: {
        limit,
        page,
        ...filter,
        // queryParams,
        // dateRange,
      },
    });
    return response.data as { pages: any; merchants: MerchantResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findMerchantApi = async ({ id }: Merchant) => {
  try {
    const response = await Api({
      method: "get",
      url: "merchants/" + id,
    });
    return response.data as MerchantResponse;
  } catch (e: any) {
    throw e;
  }
};

export const newMerchantApi = async ({ name, name_ar }: Merchant) => {
  try {
    const response = await Api({
      method: "post",
      url: "merchants/",
      data: { name, name_ar },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const updateMerchantApi = async ({
  id,
  status,
  name,
  name_ar,
}: UpdateMerchant) => {
  try {
    const response = await Api({
      method: "put",
      url: "merchants/" + id,
      data: {
        status,
        name,
        name_ar,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getMerchantsNames = async ({
  merchant_ids,
}: {
  merchant_ids: string[];
}) => {
  try {
    const response = await Api({
      method: "put",
      url: "merchantsNames/",
      data: {
        merchant_ids: merchant_ids,
      },
    });

    return response.data;
  } catch (e: any) {
    throw e;
  }
};
