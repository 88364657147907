import { FC, useEffect, useLayoutEffect, useState } from "react";
import { ReconciliationsFilters } from "../components/filters/reconciliations-filters";
import QueryBuilder from "../components/query-builder/query-builder";
import { dateRange } from "../components/query-builder/date-picker";
import ListReconciliations from "../components/reconciliations/list";
import DownloadReconciliationButton from "../components/shared/download-reconcilieation-button";
import Content from "../layout/content";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../custom-hooks/UsePreservedState";
import { useTranslation } from "react-i18next";

type Props = {};

const Reconciliations: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();
  const [reconiliations, setReconiliations] = useState<any[]>([]);
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@reconciliationDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@reconciliationQueryParams"
  );

  return (
    <>
      <Content
        title={t("reconciliations")}
        queryBuilder={
          <QueryBuilder
            type={"reconciliations"}
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            setDateRange={
              setDateRange as React.Dispatch<React.SetStateAction<dateRange>>
            }
            dateRange={dateRange as dateRange}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        }
        isLoading={isLoading}
        tabs={ReconciliationsFilters}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        actionBtns={
          <DownloadReconciliationButton reconciliations={reconiliations} />
        }
      >
        <ListReconciliations
          setIsLoading={setIsLoading}
          refresh={refreshList}
          setRefresh={setRefreshList}
          activeTab={activeTab}
          setReconiliationsForDownload={setReconiliations}
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
        />
      </Content>
    </>
  );
};

export default Reconciliations;
