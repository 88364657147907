import { FC, LegacyRef, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Content from "../layout/content";
import ViewReconciliation from "../components/reconciliations/view";
import { Button } from "../lib";
import { useTranslation } from "react-i18next";

type Props = {};

const Reconciliation: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="rounded">
      <Content title={t("reconcile")} hasBackButton>
        <ViewReconciliation id={id as string} />
      </Content>
    </div>
  );
};

export default Reconciliation;
