import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Input, Modal, Select, UserIcon, Errors } from "../../lib";
import { MerchantResponse } from "../../utils/api/merchants-apis";
import { createUserApi } from "../../utils/api/users-apis";

type Props = {
  isOpen: boolean;
  toggleModal: any;
  merchantId?: string;
  user: ManageUser;
  setUser: React.Dispatch<SetStateAction<ManageUser>>;
  merchants?: MerchantResponse[];
  setRefreshUsers: React.Dispatch<SetStateAction<boolean>>;
};

export type ManageUser = {
  name: string;
  mobile: string;
  merchantId: string;
  email: string;
};

export const initialUserState: ManageUser = {
  name: "",
  mobile: "",
  merchantId: "",
  email: "",
};

const ManageUserModal = ({
  isOpen,
  merchantId,
  toggleModal,
  setRefreshUsers,
  user,
  setUser,
  merchants = [],
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newAdminErrors, setNewAdminErrors] = useState<{
    [key: string]: any;
  }>({});
  const { t } = useTranslation();
  const saveUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      setNewAdminErrors({});
      e.preventDefault();
      merchantId
        ? await createUserApi({ ...user, merchantId: merchantId as string })
        : await createUserApi({ ...user });
      setRefreshUsers((prevState) => !prevState);
      setIsLoading(false);
      close();
    } catch (e: any) {
      console.log({ e });
      setNewAdminErrors(e);
      setIsLoading(false);
    }
  };

  const close = () => {
    setUser(() => initialUserState);
    setNewAdminErrors(() => ({}));
    setIsLoading(() => false);
    toggleModal();
  };
  const { i18n } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t("newUser")}
      subTitle={t("user")}
      icon={UserIcon}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <div className="flex flex-col">
        <form>
          <Input
            type="text"
            name="name"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({ ...prevState, name: e }))
            }
            value={user.name}
            label={t("name") as string}
            errors={newAdminErrors?.name || []}
          />
          <Input
            label={t("email") as string}
            type="email"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({
                ...prevState,
                email: e,
              }))
            }
            value={user.email}
            name="Email"
            errors={newAdminErrors?.email || []}
          />

          <Input
            type="text"
            label={t("mobile") as string}
            value={user.mobile}
            name="Mobile"
            onChange={(e) =>
              setUser((prevState: ManageUser) => ({
                ...prevState,
                mobile: e,
              }))
            }
            errors={newAdminErrors?.mobile || []}
            prefix="+966"
          />

          <div
            className={
              i18n.language == "ar" ? "text-right my-2" : "text-left my-2"
            }
          >
            {!merchantId ? (
              merchants.length ? (
                <Select
                  data={merchants as { [key: string]: any }[]}
                  valueKey="id"
                  displayKey="name"
                  onChange={(merchantId) => {
                    setUser((prevState: ManageUser) => ({
                      ...prevState,
                      merchantId,
                    }));
                  }}
                  label={t("merchant")}
                  placeholder={t("selectMerchant")}
                  name={t("merchant")}
                  value={user.merchantId}
                  errors={newAdminErrors?.merchant_id || []}
                />
              ) : (
                <>
                  <Select
                    isDisabled
                    data={merchants as { [key: string]: any }[]}
                    valueKey="id"
                    displayKey="name"
                    onChange={(merchantId) => {
                      setUser((prevState: ManageUser) => ({
                        ...prevState,
                        merchantId,
                      }));
                    }}
                    label={t("merchant")}
                    placeholder={t("selectMerchant")}
                    name={"merchant"}
                    value={user.merchantId}
                    errors={
                      newAdminErrors?.merchant_id || [
                        "No merchants available, you may add merchants first",
                      ]
                    }
                  />
                </>
              )
            ) : null}
          </div>
          <Button
            onClick={saveUser}
            label={t("save") as string}
            colorScheme="blue"
            classNames="my-4 "
          />
        </form>
      </div>
    </Modal>
  );
};

export default ManageUserModal;
