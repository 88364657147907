import { FC, useState } from "react";
import { Input, MerchantIcon } from "../../lib";
import { Modal } from "../../lib/modal";
import { Button } from "../../lib";
import {
  newMerchantApi,
  updateMerchantApi,
} from "../../utils/api/merchants-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { useTranslation } from "react-i18next";

export type ManageMerchant = {
  id?: string;
  name: string;
  name_ar?: string;
};

export const initialNewMerchantState: ManageMerchant = {
  name: "",
};

type Props = {
  isOpen: boolean;
  newMerchant: ManageMerchant;
  refreshMerchant: React.Dispatch<React.SetStateAction<boolean>>;
  setNewMerchant: React.Dispatch<React.SetStateAction<ManageMerchant>>;
  toggleModal: () => void;
};

export const ManageMerchantModal: FC<Props> = ({
  isOpen,
  toggleModal,
  newMerchant,
  setNewMerchant,
  refreshMerchant,
}) => {
  const [createMerchantError, setCreateMerchantError] = useState<{
    [key: string]: string[];
  }>({});

  const [isLoading, setIsLoading] = useState(false);
  const save = async (e: any) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      if (newMerchant.id) {
        await updateMerchantApi({
          id: newMerchant.id,
          name: newMerchant.name,
          name_ar: newMerchant.name_ar,
        });
      } else
        await newMerchantApi({
          name: newMerchant.name,
          name_ar: newMerchant.name_ar,
        });
      refreshMerchant((prevState: boolean) => !prevState);
      toggleModal();
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setCreateMerchantError({ ...e?.message });
    }
  };
  const close = () => {
    setNewMerchant(initialNewMerchantState);
    toggleModal();
    refreshMerchant((prevState: boolean) => !prevState);
    setIsLoading(false);
  };
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      close={() => close()}
      icon={MerchantIcon}
      title={newMerchant.id ? t("editMerchant") : t("createMerchant")}
      subTitle={t("merchant")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form>
        <Input
          label={t("name")}
          name="merchant-name"
          errors={createMerchantError?.name}
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              name: e,
            }))
          }
          value={newMerchant?.name || ""}
        />
        <Input
          label={t("arabicName")}
          name="merchant-arabic-name"
          errors={createMerchantError?.name_ar}
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              name_ar: e,
            }))
          }
          value={newMerchant?.name_ar || ""}
        />
        <div className="mt-6">
          <Button
            onClick={save}
            label={t("save") as string}
            colorScheme="blue"
          />
        </div>
      </form>
    </Modal>
  );
};
