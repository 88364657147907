import { Filters } from ".";

export const TerminalsFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Unassigned",
    key: "user_id",
    value: "",
  },
  {
    header: "Not Connected",
    key: "device_id",
    value: "",
  },
];
// is active and blocked is not working due to the lack of provided information that comes from the BE...
