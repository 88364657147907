import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../forms";
import { UserIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";

interface AdminItem {
  id: string;
  name: string;
  mobile: string;
  is_active: boolean;
  created_at: string;
}

interface AdminListItemType {
  role: "admin" | "client";
  onClick: (item: any) => void;
  actionButtonOnClick?: (e: Event, item: any) => void;
  item: AdminItem;
}

export const AdminListItem: FC<AdminListItemType> = ({
  role,
  onClick,
  actionButtonOnClick,
  item,
}) => {
  const { t } = useTranslation();
  const adminBadge: BadgeType = item.is_active
    ? { label: t("active") }
    : { label: t("notActive"), color: "red" };
  return (
    <Card
      classNames="lg:py-4 lg:px-4 p-2 w-full mt-3"
      onClick={onClick ? () => onClick(item) : undefined}
    >
      <div className="flex flex-row items-center">
        <UserIcon containerClassNames="lg:pr-4" />
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 lg:my-1 text-sm lg:text-base">
            <span className="font-bold">{item.name}</span>
            <div className="hidden lg:flex">
              <Badge {...adminBadge} isRounded={true} size="sm" />
            </div>
          </div>
          <span className="text-gray-600 text-xs">{item.mobile}</span>
        </div>
        {actionButtonOnClick ? (
          <div className="flex-initial">
            <Button
              label={t("revokeAccess") as string}
              isRounded={false}
              onClick={(e: Event) => actionButtonOnClick(e, item)}
              colorScheme="red"
              size="sm"
            />
          </div>
        ) : null}
      </div>
    </Card>
  );
};
