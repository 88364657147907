import jsonexport from "jsonexport/dist";
import { useTranslation } from "react-i18next";
import { Button } from "../../lib";

const scheme = {
  P1: "MADA",
  MC: "Mastercard",
  VC: "Visa Credit",
  AX: "American Express",
  DM: "Maestro",
  GN: "GCCNET",
};

const DownloadTransactionsButton = ({ transactions, classNames }: any) => {
  const { t } = useTranslation();
  const download = () => {
    transactions.map((transaction: typeof transactions[0]) => {
      // transaction = {...transaction,card_expiration:transaction.receipt}
      transaction.card_expiration =
        transaction.receipts[0]?.card_expiration?.substring(0, 2) +
        "/" +
        transaction.receipts[0]?.card_expiration?.substring(2, 4);
      if (transaction.response == null) delete transaction.response;
    });
    let output: string[] = [];
    jsonexport(
      transactions,
      { includeHeaders: true, rowDelimiter: ",", endOfLine: "\n" },
      function (err: Error, csv: string) {
        if (err) return console.log(err);
        output.push(csv);
      }
    );
    output[0] = "\ufeff" + output[0];
    const outFile = new File(
      output,
      "Transactions-" + new Date().toDateString() + ".csv"
    );
    const link = document.createElement("a");
    const url = URL.createObjectURL(outFile);
    link.href = url;
    link.download = outFile.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={() => download()}
      colorScheme={"skyblue"}
      label={t("downloadTransactions") as string}
      classNames={classNames}
    />
  );
};

export default DownloadTransactionsButton;
