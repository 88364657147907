import { FC, SetStateAction, useEffect, useState } from "react";
import { findTransactionApi } from "../../utils/api/transactions-apis";
import ExternalLinkIcon from "remixicon-react/ExternalLinkLineIcon";
import {
  ClientListItem,
  formatPrice,
  MerchantsListItem,
  TerminalListItem,
  Tick,
  UserListItem,
} from "../../lib";
import { default as Mada } from "../../lib/icons/mada.png";
import { default as American_Express } from "../../lib/icons/American_Express.png";
import { default as UnionPay } from "../../lib/icons/UnionPay.png";
import { default as Visa } from "../../lib/icons/visa.png";
import { default as Discover } from "../../lib/icons/Discover.png";
import { default as Maestro } from "../../lib/icons/Maestro.png";
import { default as GCCNET } from "../../lib/icons/GCCNET.png";
import { default as JCB } from "../../lib/icons/JCB.png";
import { default as Mastercard } from "../../lib/icons/mastercard.png";
import { DeviceListItem } from "../../lib/list-item/device-list";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
  Transaction,
  transactionClient,
  transactionDevice,
  transactionMerchant,
  transactionTerminal,
} from "../../types/transaction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const PaymentSchemeIcons: { [key: string]: string } = {
  P1: Mada,
  VC: Visa,
  MC: Mastercard,
  DM: Maestro,
  AX: American_Express,
  JC: JCB,
  DC: Discover,
  UP: UnionPay,
  GN: GCCNET,
};

const ViewTransaction: FC<Props> = ({ id, setIsLoading }: Props) => {
  const [transaction, setTransaction] = useState<Transaction>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB05iZPF690MgQD6ofRHY2rSl5hyUoI_hg",
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const findTransaction = async () => {
    try {
      setIsLoading(true);
      const transaction = await findTransactionApi({ id });
      setTransaction((prevState: any) => transaction as Transaction);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };
  const goToTerminal = (terminal: any) =>
    navigate("/terminals/" + transaction?.terminal.id);

  useEffect(() => {
    findTransaction();
  }, []);

  return (
    <div className="">
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col lg:flex-row items-center justify-between ">
          <span className="font-bold text-2xl lg:text-3xl items-end mb-2 lg:mb-0">
            {formatPrice(
              transaction?.receipts?.[0].amount_authorized?.value as string,
              i18n.language == "ar"
                ? transaction?.receipts?.[0]?.currency?.arabic
                : transaction?.receipts?.[0]?.currency?.english
            )}
          </span>
          <div className="flex flex-row items-center gap-x-2 lg:gap-x-0 lg:flex-col text-sm lg:text-base">
            <div>{t("date&time")}</div>
            <div className="text-lg lg:text-xl font-bold">
              {transaction?.receipts?.[0].start_date +
                " " +
                transaction?.receipts?.[0].start_time}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 mt-4">
          <div className="lg:col-span-2">
            {transaction?.receipts?.map((i, index) => (
              <div
                key={index}
                className="p-0 border-2 border-gray-300 shadow-sm rounded-md my-2"
              >
                <div className="border-b flex flex-col py-2 px-4">
                  <span className="text-md lg:text-lg">{t("type")}</span>
                  <span className="text-lg lg:text-xl font-bold capitalize">
                    {i18n.language == "ar"
                      ? i?.transaction_type?.name?.arabic
                      : i?.transaction_type?.name?.english}
                  </span>
                </div>
                <div className="border-b flex flex-col py-2 px-4">
                  <span className="text-md lg:text-lg">
                    {t("verificationMethod")}
                  </span>
                  <span className="text-lg lg:text-xl font-bold">
                    {i18n.language == "ar"
                      ? i?.verification_method?.arabic
                      : i?.verification_method?.english}
                  </span>
                </div>
                <div className="border-b flex flex-col py-2 px-4">
                  <span className="text-md lg:text-lg">{t("RRN")}</span>
                  <span className="text-lg lg:text-xl font-bold">
                    #{i?.retrieval_reference_number}
                  </span>
                </div>
                <div className="border-b flex flex-col py-2 px-4">
                  <span className="text-md lg:text-lg">{t("STAN")}</span>
                  <span className="text-lg lg:text-xl font-bold">
                    #{i?.system_trace_audit_number}
                  </span>
                </div>
                <div className="border-b flex flex-col py-2 px-4">
                  <span className="text-md lg:text-lg">{t("actionCode")}</span>
                  <span className="text-lg lg:text-xl font-bold">
                    {i.action_code}{" "}
                    <span className="text-green-500">
                      {i18n.language == "ar"
                        ? i?.status_message?.arabic
                        : i?.status_message?.english}
                    </span>
                  </span>
                </div>
                <div className="border-b flex items-center justify-center py-4 px-4 ">
                  <a
                    href={i.qr_code}
                    target="_blank"
                    className="font-bold text-lg lg:text-xl flex flex-row"
                  >
                    <ExternalLinkIcon size={25} className="mx-2" />
                    {t("seeReceipt")}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="lg:px-4">
            <div className="flex flex-row justify-between items-center">
              <div className="flex-initial text-center flex flex-col justify-center items-center text-sm lg:text-base">
                {transaction?.transaction_receipt?.status_message?.english ==
                  "reconciled" ||
                transaction?.transaction_receipt?.status_message?.english ==
                  "approved" ||
                transaction?.transaction_receipt?.status_message?.english ==
                  "created" ||
                transaction?.is_approved ||
                transaction?.is_reconcilied == true ? (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-green-600">
                    <Tick color="text-white" />
                  </div>
                ) : (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-gray-400"></div>
                )}
                {t("created")}
              </div>
              <div className="flex-1 border-b border-gray-300 mb-5"></div>
              <div className="flex-initial text-center flex flex-col justify-center items-center text-sm lg:text-base">
                {transaction?.is_approved ? (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-green-600">
                    <Tick color="text-white" />
                  </div>
                ) : (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-gray-400"></div>
                )}
                {t("approved")}
              </div>
              <div className="flex-1 border-b border-gray-300 mb-5"></div>
              <div className="flex-initial text-center flex flex-col justify-center items-center text-sm lg:text-base">
                {transaction?.is_reconcilied ? (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-green-600">
                    <Tick color="text-white" />
                  </div>
                ) : (
                  <div className="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full bg-gray-400"></div>
                )}
                {t("reconciled")}
              </div>
            </div>
            <div className="p-0 border-2 border-gray-300 shadow-sm rounded-md flex flex-col mt-6 p-4">
              <div className="flex-1 flex items-center justify-between mb-6">
                <img
                  src={
                    PaymentSchemeIcons[transaction?.card_scheme_id as string]
                  }
                  className="w-10 lg:w-20"
                />
                <span className="uppercase text-lg font-bold">
                  {t("creditCard")}
                </span>
              </div>
              <div className="flex-initial text-lg font-bold">
                <span>{transaction?.receipts?.[0]?.pan}</span>
              </div>
              <div className="flex-initial text-lg font-bold flex items-center justify-between mt-4">
                <span>{transaction?.receipts?.[0]?.card_expiration}</span>
              </div>
            </div>
            <div className="relative h-80 w-full min-h-max mt-4 border-2 rounded-md ">
              {isLoaded && transaction?.lat && transaction?.lon ? (
                <>
                  <GoogleMap
                    center={{
                      lat: parseFloat(transaction?.lat as string),
                      lng: parseFloat(transaction?.lon as string),
                    }}
                    zoom={15}
                    mapContainerClassName={
                      "w-full h-72  border-2 rounded-t-md "
                    }
                  >
                    <Marker
                      position={{
                        lat: parseFloat(transaction?.lat as string),
                        lng: parseFloat(transaction?.lon as string),
                      }}
                    />
                  </GoogleMap>
                  <div className="absolute rounded-b-md -bottom-0 w-full h-24 bg-gradient-to-t from-white via-gray-100 z-0 flex justify-between px-10 pt-10">
                    {/** Title Should be the Location Name */}
                    <div className="flex flex-col items-start justify-center">
                      <div className="font-bold text-sm pt-2 text-start">
                        Saudi Arabia
                      </div>
                      <div className="text-sm text-start">Riyadh, Almlqa </div>
                    </div>
                    <div>
                      <div
                        className="pt-5"
                        onClick={() => console.log("map info")}
                      >
                        <ExternalLinkIcon size={25} />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-6">
          <div className="lg:col-span-2">
            <TerminalListItem
              title={t("terminal")}
              role="client"
              item={transaction?.terminal as transactionTerminal}
              onClick={(terminal: any) => goToTerminal(terminal)}
            />
          </div>
          <div className="">
            <ClientListItem
              title={t("client")}
              role="client"
              item={transaction?.client as transactionClient}
            />
          </div>
          <div className="">
            <MerchantsListItem
              title={t("merchant")}
              role="client"
              item={transaction?.merchant as transactionMerchant}
            />
          </div>
          <div className="">
            <UserListItem
              title={t("user")}
              role="client"
              item={transaction?.user ? transaction.user : {}}
            />
          </div>
          <div className="">
            <DeviceListItem
              title={t("device")}
              role="client"
              item={transaction?.device as transactionDevice}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTransaction;
