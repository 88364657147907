import { FC } from "react";
import Routes from "./utils/router";
import { HashRouter } from "react-router-dom";

import { UserContextProvider } from "./contexts/UserContext";
import { LoadingContextProvider } from "./contexts/LoadingContext";
import { useTranslation } from "react-i18next";

const App: FC = ({}) => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <>
      <HashRouter>
        <LoadingContextProvider>
          <UserContextProvider>
            <Routes />
          </UserContextProvider>
        </LoadingContextProvider>
      </HashRouter>
    </>
  );
};

export default App;
