import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TerminalsFilters } from "../components/filters/terminals-filters";
import ManageTerminals from "../components/modal/manage-terminals";
import TerminalUploadModal from "../components/modal/terminal-upload";
import ListTerminals from "../components/terminals/list";
import Content from "../layout/content";
import { Button } from "../lib";
import {
  listMerchantsApi,
  MerchantResponse,
} from "../utils/api/merchants-apis";
import { listTRSMsApi, ListTRSMResponseApi } from "../utils/api/trsm-apis";

type Props = {};

const Terminals: FC<Props> = ({}: Props) => {
  const [showTerminalsModal, setShowTerminalsModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();

  const [trsms, setTrsms] = useState<ListTRSMResponseApi>(
    {} as ListTRSMResponseApi
  );
  const [merchants, setMerchants] = useState<MerchantResponse[]>([]);
  const [recordsFromFile, setRecordsFromFile] = useState<{
    [key: string]: any;
  }>({});
  const [FilesReadingErrors, setFilesReadingErrors] = useState<string[]>([]);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  const fetchMerchants = async () => {
    const { merchants } = await listMerchantsApi({ limit: 1000 });
    setMerchants(merchants);
    setIsLoading(false);
  };
  const toggleFileUploadModal = () => {
    setIsFileUploadModalOpen((prevState) => !prevState);
  };
  const fetchTRSMs = async ({
    page,
    search,
  }: {
    page?: number;
    search?: string;
  }) => {
    let TRSMSResponse = await listTRSMsApi({
      page,
      limit: 5,
      is_not_assigned: true,
      search,
    });
    setTrsms(TRSMSResponse);
  };

  useEffect(() => {
    if (Object.keys(recordsFromFile).length) {
      setIsFileUploadModalOpen((prevState: boolean) => !prevState);
    }
  }, [recordsFromFile]);

  const toggleModal = () =>
    setShowTerminalsModal((prevState: boolean) => !prevState);

  useEffect(() => {
    fetchMerchants();
    fetchTRSMs({});
  }, [refreshList]);

  return (
    <Content
      title={t("terminals")}
      isLoading={isLoading}
      actionBtns={
        <Button
          colorScheme="skyblue"
          onClick={toggleModal}
          label={t("createTerminal") as string}
          classNames="min-w-max"
          isRounded={true}
        />
        // -------------------------------------------------------- don't remove, needed in future improvments ----------------------------------------------------
        // <div className="flex justify-between sm:h-10 md:h-16 lg:h-16 gap-2 ">
        //   {/* <FileBtn
        //     type="terminals"
        //     label={"Upload TRSMs"}
        //     name={"Upload TRSMs"}
        //     classNames={""}
        //     colorScheme={"skyblue"}
        //     size={"lg"}
        //     isRounded
        //     onClick={() => {
        //       setFilesReadingErrors((prev) => []);
        //     }}
        //     fileType={"text/csv"}
        //     setResult={setRecordsFromFile}
        //     setFilesReadingErrors={setFilesReadingErrors}
        //   /> */}
        // </div>
      }
      tabs={TerminalsFilters}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
    >
      <ListTerminals
        refresh={refreshList}
        setRefresh={setRefreshList}
        setIsLoading={setIsLoading}
        activeTab={activeTab}
      />
      <ManageTerminals
        isOpen={showTerminalsModal}
        toggleModal={toggleModal}
        merchants={merchants}
        trsms={trsms}
        refreshList={() => setRefreshList((prevState: boolean) => !prevState)}
        fetchTRSMs={fetchTRSMs}
        setTrsms={setTrsms}
      />

      <TerminalUploadModal
        isFileUploadModalOpen={isFileUploadModalOpen}
        toggleModal={toggleFileUploadModal}
        recordsFromFile={recordsFromFile}
        type="terminals"
      />
    </Content>
  );
};

export default Terminals;
