import { FC, Fragment, useContext, useState } from "react";

import { Menu, Transition } from "@headlessui/react";

import UserContext, { UserContextType } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import logo from "./../assets/icons/logo.png";
import { Input, SearchIcon } from "../lib";
import { useTranslation } from "react-i18next";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Header: FC = () => {
  const [search, setSearch] = useState("");
  const { i18n } = useTranslation();
  const { logout, toggleModal, admin } = useContext(
    UserContext
  ) as UserContextType;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logoutAndRedirect = () => {
    logout();
    navigate("/login");
  };
  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language == "ar" ? "en" : "ar");
  };

  const userNavigation: any = [
    { name: t("switchClient"), onClick: toggleModal },
    { name: t("logout"), onClick: logoutAndRedirect },
  ];

  return (
    <div className=" flex-shrink min-h-max bg-white flex flex-col border-b border-gray-200  ">
      <div className="px-4 bg-white">
        <div className="hidden lg:flex flex-1 flex justify-between lg:px-0 my-2">
          <div className="min-w-max min-h-max flex flex-col items-start">
            <div className="my-2">
              {admin?.clients[0]?.logo ? (
                <div className="flex-col">
                  <img src={admin?.clients[0]?.logo} className="client-logo" />
                  <span className="text-xs flex-1 text-gray-300">
                    Powered By Nearpay
                  </span>
                </div>
              ) : (
                <div className="text-3xl font-semibold flex flex-col">
                  {admin?.clients[0]?.name}
                  <span className="text-xs flex-1 text-gray-300">
                    Powered By Nearpay
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 flex">
            {/* <form
              className="w-full flex justify-center items-center lg:ml-0"
              action="#"
              method="GET"
            >
              <div className="w-1/3">
                <Input
                  name="search"
                  type="search"
                  placeholder="search"
                  value={search}
                  onChange={setSearch}
                  icon={SearchIcon}
                  label=""
                />
              </div>
            </form> */}
          </div>
          <div className="ml-4 flex items-center lg:ml-6">
            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div className="flex gap-2">
                <div
                  className="hover:bg-gray-200 rounded-full p-1 flex items-center "
                  onClick={() => switchLanguage()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="30"
                    height="30"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301 14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z" />
                  </svg>
                </div>
                <Menu.Button className="z-100 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <div className="flex rounded-full border-3 border-gray-200 p-2 px-6 gap-4 items-center ">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
                      </svg>
                    </div>
                    <div className="mr-2">{admin?.name}</div>
                  </div>
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={
                    "z-30 origin-top-right absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none " +
                    t("alignMenu")
                  }
                >
                  {userNavigation.map((item: any) => (
                    <Menu.Item key={item.name}>
                      {({ active }: any) => (
                        <button
                          onClick={item.onClick}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block py-2 px-4 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
