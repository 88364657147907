import {
  FC,
  useState,
  createContext,
  Context,
  ReactNode,
  useEffect,
} from "react";
import SwitchClient from "../components/modal/switch-client";
import Storage from "../utils/storage";

interface Client {
  id: number;
  name: string;
  logo: string;
  vendor_id: number;
}

interface Admin {
  id: number | null;
  name: string;
  mobile: string;
  email: string;
  created_at: Date | string;
  clients: Client[];
}

export type UserContextType = {
  admin: Admin;
  hasToken: () => boolean;
  addToken: (accessToken: string, refreshToken: string) => void;
  getUser: () => Admin;
  logout: Function;
  toggleModal: () => void;
};

type Props = {
  children: ReactNode;
};

const UserContext: Context<UserContextType | null> =
  createContext<UserContextType | null>(null);

const initialAdminState: Admin = {
  id: null,
  name: "",
  mobile: "",
  email: "",
  created_at: "",
  clients: [],
};

export const UserContextProvider: FC<Props> = ({ children }: Props) => {
  const [refreshAccess, setRefreshAccess] = useState(false);
  const [showSelectClientModal, setShowSelectClientModal] = useState(false);
  const [admin, setAdmin] = useState<Admin>(initialAdminState);

  const toggleModal = () =>
    setShowSelectClientModal((prevState: boolean) => !prevState);

  const getToken = () => {
    return {
      accessToken: Storage.get("accessToken") || "",
      refreshToken: Storage.get("refreshToken") || "",
    };
  };

  const getUser = () => {
    const admin = Storage.get("admin");

    if (!admin) {
      let token = Storage.get("accessToken");

      if (token) {
        let admin = decodeJWT(token);
        setAdmin(admin);
        return admin;
      }
      setAdmin(initialAdminState);
      // logout();
    }
    setAdmin(admin);
    return admin;
  };

  const hasToken = (): boolean => {
    let { accessToken } = getToken();
    return accessToken ? true : false;
  };

  const addToken = (accessToken: string, refreshToken: string) => {
    Storage.set("accessToken", accessToken);
    Storage.set("refreshToken", refreshToken);
    Storage.set("admin", decodeJWT(accessToken));
    setActiveClient(getUser()?.clients[0]?.id);
  };

  const setActiveClient = (clientId: string) => {
    Storage.set("clientId", clientId);
    setRefreshAccess((prevState: boolean) => !prevState);
  };

  const decodeJWT = (jwt: string) => {
    let token = jwt.split(".")[1];
    if (!token) return { accessToken: jwt };
    let base64 = token.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload)?.data;
  };

  const switchClient = (clientId: number) => {
    const clients = [
      ...admin.clients.filter((i: Client) => i.id == clientId),
      ...admin.clients.filter((i: Client) => i.id != clientId),
    ];
    Storage.set("admin", { ...admin, clients });
    Storage.set("clientId", clientId);
    setRefreshAccess((prevState) => !prevState);
    toggleModal();
    window.location.reload();
  };

  const logout = () => {
    Storage.remove("accessToken");
    Storage.remove("refreshToken");
    Storage.remove("clientId");
    Storage.remove("admin");
    setRefreshAccess((prevState) => !prevState);
  };

  useEffect(() => {
    getUser();
  }, [refreshAccess]);

  return (
    <UserContext.Provider
      value={{
        admin,
        hasToken,
        addToken,
        getUser,
        logout,
        toggleModal,
      }}
    >
      {children}
      <SwitchClient
        isOpen={showSelectClientModal}
        toggleModal={toggleModal}
        clients={admin?.clients || []}
        selectClient={switchClient}
      />
    </UserContext.Provider>
  );
};

export default UserContext;
