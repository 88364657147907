import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Card, formatPrice, Remove, Tick } from "../../lib";
import { ReconiliationItem } from "../../types/reconciliations";
import { findReconiliationApi } from "../../utils/api/reconcile-apis";
type Props = {
  id: string;
};

const ViewReconciliation: FC<Props> = ({ id }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reconciliation, setReconiliation] = useState<ReconiliationItem>(
    {} as ReconiliationItem
  );
  const { t, i18n } = useTranslation();
  const findReconiliation = async () => {
    try {
      const { reconciliation } = await findReconiliationApi({ id });
      setReconiliation(reconciliation);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    findReconiliation();
  }, []);
  function camelCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  return (
    <div>
      <LoadingSpinner isLoading={isLoading} />
      <div className="flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row justify-between items-center mb-4 ">
        <div className="flex flex-row items-center lg:jusitfy-start gap-4 flex-1">
          <span className="font-medium text-2xl lg:text-3xl">
            <span className="font-bold">
              {formatPrice(Number(reconciliation?.details?.total?.total))}
            </span>
          </span>
          <div className="flex flex-row gap-x-2 items-center">
            {reconciliation?.is_balanced?.value ? (
              <>
                <div className="rounded-full bg-green-700">
                  <Tick color="text-white" size={18} />
                </div>
                <span className="font-bold">
                  {i18n.language == "ar"
                    ? reconciliation.is_balanced?.label?.arabic
                    : reconciliation.is_balanced?.label?.english}
                </span>
              </>
            ) : (
              <>
                <div className="rounded-full bg-red-600">
                  <Remove color="text-white" size={18} />
                </div>
                <span className="font-bold">
                  {i18n.language == "ar"
                    ? reconciliation.is_balanced?.label?.arabic
                    : reconciliation?.is_balanced?.label?.english}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end flex-1">
          <div className="flex flex-row gap-x-2 lg:gap-x-0 items-center lg:flex-col justify-between">
            <span className="text-md float-left">{t("date&time")}</span>
            <span className="font-bold text-lg">
              {reconciliation?.date} {reconciliation?.time}
            </span>
          </div>
        </div>
      </div>
      <span className="block text-lg lg:text-2xl font-bold mb-2">
        {t("terminalDetails")}
      </span>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mb-8">
        {Object.keys(reconciliation?.details || []).map(
          (key: any, index: number) => {
            if (key == "total") return;
            const item =
              reconciliation?.details[
                key as keyof typeof reconciliation.details
              ];
            return (
              <Card key={index} classNames="px-4 py-4">
                <div className="flex flex-col">
                  <span className="font-bold text-md">
                    {t(camelCase(item?.label?.english))} ({item?.count})
                  </span>
                  <span className="text-2xl font-bold">
                    {formatPrice(item?.total)}
                  </span>
                </div>
              </Card>
            );
          }
        )}
      </div>
      <br />
      <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-3 gap-2">
        {reconciliation?.schemes?.map((item: any, index: number) => (
          <PaymentSchemeReconciliation key={index} item={item} />
        ))}
      </div>
      <div className="sm:w-10 md:w-20 lg:w-32 mt-5">
        <a href={reconciliation?.qr_code} target="_blank">
          <Button
            onClick={() => {}}
            label={t("seeReceipt") as string}
            colorScheme="default"
            isRounded={true}
          />
        </a>
      </div>
    </div>
  );
};

const PaymentSchemeReconciliation: FC<{ item: any }> = ({ item }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card classNames="px-4 py-4">
      <div className="flex-initial flex flex-row items-center gap-x-6 mb-2">
        <div className="flex flex-col gap-y-2 items-center">
          <div className="rounded-full bg-green-700">
            <Tick color="text-white" size={18} />
          </div>
          <span className="font-bold">{t("balanced")}</span>
        </div>
        <div className="flex-1 flex flex-col">
          <span className="text-xl">{t("type")}</span>
          <span className="text-2xl font-bold">
            {item?.name?.label?.english}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-4">
        <span></span>
        <span className="font-bold text-md">{t("POS")}</span>
        <span className="font-bold text-md">{t("HOST")}</span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("debitCard")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.debit?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.debit?.total)}
        </span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("debitCount")}</span>
        <span className="font-bold text-md">{item?.pos?.debit?.count}</span>
        <span className="font-bold text-md">{item?.host?.debit?.count}</span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("creditCard")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.credit?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.credit?.total)}
        </span>
      </div>
      <div className="grid grid-cols-3 border-b border-gray-300 py-2">
        <span className="text-md">{t("creditCount")}</span>
        <span className="font-bold text-md">{item?.pos?.credit?.count}</span>
        <span className="font-bold text-md">{item?.host?.credit?.count}</span>
      </div>
      <div className="grid grid-cols-3 py-4">
        <span className="text-md font-bold">{t("total")}</span>
        <span className="font-bold text-md">
          {formatPrice(item?.pos?.total?.total)}
        </span>
        <span className="font-bold text-md">
          {formatPrice(item?.host?.total?.total)}
        </span>
      </div>
      <div className="black h-10 w-10"></div>
    </Card>
  );
};

export default ViewReconciliation;
