import { FC, Context, createContext, ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
  height?: number;
};

export type LoadingContextType = {
  isLoading: boolean;
  toggleLoading: () => void;
};

const LoadingContext: Context<LoadingContextType | null> =
  createContext<LoadingContextType | null>(null);

export const LoadingContextProvider: FC<Props> = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading: () => void = () =>
    setIsLoading((prevState) => !prevState);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        toggleLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
