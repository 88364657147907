import { FC, useContext, useEffect, useState } from "react";
import { listAdminsApi } from "../../utils/api/admins-apis";
import { AdminsFilters, createFilterObject } from "../filters";
import { AdminListItem, EmptyState, GroupedList } from "../../lib";
import { useTranslation } from "react-i18next";

type Props = {
  refresh: boolean;
  setIsLoading: (state: boolean) => void;
  activeTab: string;
  merchant_id?: number;
  limit?: number;
};

const ListAdmins: FC<Props> = ({
  refresh,
  setIsLoading,
  activeTab,
  merchant_id,
  limit = 30,
}: Props) => {
  const [admins, setAdmins] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const { t } = useTranslation();

  const fetchAdmins = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listAdminsApi({
        page,
        filter: createFilterObject(activeTab, AdminsFilters),
        limit,
        merchant_id,
      });
      setPages(response.pages);
      setAdmins(response.admins);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [refresh, activeTab]);

  return admins.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchAdmins(page),
      }}
      role={"client"}
      data={admins}
      component={AdminListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListAdmins;
