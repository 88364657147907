import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MerchantIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";

interface MerchantItem {
  id: string;
  name: string;
  status: "active" | "deactive";
  created_at: string;
}

export interface MerchantListItemType {
  role: "admin" | "client";
  title?: string;
  onClick?: (item?: any) => void;
  item: MerchantItem;
}

export const MerchantsListItem: FC<MerchantListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t } = useTranslation();
  const merchantBadge: BadgeType =
    item?.status == "active"
      ? { label: t("active") }
      : { label: t("notActive"), color: "red" };
  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full" onClick={onClick}>
        <div className="flex flex-row items-center">
          <MerchantIcon containerClassNames="pr-4" />
          <div className="flex flex-col flex-1">
            <span className="font-bold text-sm lg:text-base">
              {item?.name || "--"}
            </span>
            <span className="text-gray-600 text-xs">#{item?.id}</span>
          </div>
          <div className="flex-initial">
            <Badge {...merchantBadge} isRounded={true} />
          </div>
        </div>
      </Card>
    </div>
  );
};
