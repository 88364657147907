import Api, { List } from ".";
import { dateRange } from "../../components/query-builder/date-picker";

interface Admins extends List {
  filter: { [key: string]: any };
  merchant_id?: number;
}
interface Admin {
  id: string;
}
interface AdminResponse {
  name: string;
  mobile: string;
  is_active: boolean;
  created_at: string;
}
type admin = {
  name: string;
  mobile: string;
  email: string;
  type: string;
  bank_id: string;
};

export const listAdminsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchant_id,
}: Admins) => {
  try {
    const response = await Api({
      method: "get",
      url: "admins",
      params: {
        limit,
        page,
        ...filter,
        merchant_id,
      },
    });
    return response.data as { pages: any; admins: AdminResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findAdminApi = async ({ id }: Admin) => {
  try {
    const response = await Api({
      method: "get",
      url: "admins/" + id,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const createAdminApi = async (admin: admin) => {
  try {
    const response = await Api({
      method: "post",
      url: "admins/",
      data: {
        ...admin,
        mobile: "+966" + admin.mobile,
        type: "client",
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
