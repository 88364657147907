import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  EmptyState,
  Invite,
  MerchantsListItem,
  TerminalIcon,
  UserListItem,
} from "../../lib";
import { DeviceListItem } from "../../lib/list-item/device-list";
import { Badge, BadgeType } from "../../lib/shared/badge";
import {
  fileDownloadApi,
  findTerminalApi,
  unAssginTerminalApi,
  UnAssignEnum,
} from "../../utils/api/terminals-apis";
import AssignTerminalToUserModal from "../modal/assign-terminal-to-user";
import ListReconciliations from "../reconciliations/list";
import ListTransactions from "../transactions/list";
import moment from "moment";

type Props = {
  id: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const ViewTerminal = ({ id, setIsLoading, isLoading }: Props) => {
  const [terminal, setTerminal] = useState<{ [key: string]: any }>({});

  const [refreshTerminal, setRefreshTerminal] = useState(false);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [refreshReconciliations, setRefreshReconciliations] = useState(false);
  const [isAssignToUserModalOpen, setIsAssignToUserModal] = useState(false);
  const [reconciliationsIsLoading, setReconciliationsIsLoading] =
    useState(true);
  const [transactionsIsLoading, setTransactionsIsLoading] = useState(true);
  const { t } = useTranslation();

  const toggleAssignUserModal = () => {
    setIsAssignToUserModal((prevState: boolean) => !prevState);
  };

  const UnAssignTerminal = async (type: UnAssignEnum) => {
    if (window.confirm("Are you sure, you want to remove access ?")) {
      await unAssginTerminalApi({
        id: terminal.id,
        type,
      });

      setRefreshTerminal((prevState) => !prevState);
    }
  };

  const fileDownload = async (isFull: boolean) => {
    setIsLoading(true);
    await fileDownloadApi({ id: terminal.id, isFull });
    setRefreshTerminal((prev) => !prev);
  };

  const findTerminal = async () => {
    try {
      setIsLoading(true);
      const { terminal } = await findTerminalApi({ id: id as string });
      // const profileDate = new Date(profile.updated_at);
      // profile.updated_at = [
      //   `${profileDate.getFullYear()}-${
      //     profileDate.getMonth() + 1
      //   }-${profileDate.getDate()}`,
      //   `${
      //     profileDate.getHours() % 12
      //   }:${profileDate.getMinutes()}:${profileDate.getSeconds()} ${
      //     profileDate.getHours() > 12 ? "PM" : "AM"
      //   }`,
      // ];

      setTerminal(terminal);
      // setProfile(profile);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(() => false);
  }, [terminal]);

  useEffect(() => {
    findTerminal();
  }, [refreshTerminal]);

  const isConnectedBadge: BadgeType = terminal.is_connected
    ? { label: t("active") }
    : { label: t("notActive"), color: "red" };
  const isRegisteredBadge: BadgeType = terminal.is_assigned_to_user
    ? { label: t("registered"), color: "green" }
    : { label: t("notRegistered"), color: "red" };

  return (
    <>
      {/* this is container */}
      <div className="flex flex-col">
        <div className="lg:text-3xl text-xl font-semibold">{t("info")}</div>
        <div className="flex justify-between my-4 ">
          {/* left  */}
          <div className="flex ">
            <div className="">
              <TerminalIcon />
            </div>
            <div className="flex flex-col">
              <div className="text-sm">{t("terminalsIdentifier")}</div>
              <div className="font-bold lg:text-lg text-base">
                {"#" + terminal?.tid}
              </div>
            </div>
          </div>
          {/* right  */}
          <div className="flex flex-col lg:flex-row items-center gap-4 justify-around lg:flex-initial lg:mt-0 ">
            <Badge {...isConnectedBadge} isRounded={true} />
            <Badge {...isRegisteredBadge} isRounded={true} />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div className="font-semibold mt-3">{t("merchant")}</div>
          {terminal?.merchant?.id ? (
            <MerchantsListItem role={"client"} item={terminal?.merchant} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-xl lg:text-3xl font-semibold mt-4">
            {t("access")}
          </div>
          <div className="lg:flex grid grid-cols-1 gap-4 ">
            <div className="flex flex-col flex-1 mt-3">
              {terminal.user?.name ? (
                <UserListItem
                  title={t("user")}
                  role={"client"}
                  item={terminal.user}
                  assignToUser={toggleAssignUserModal}
                  actionButtonOnClick={() =>
                    UnAssignTerminal(UnAssignEnum.user)
                  }
                />
              ) : (
                <EmptyState
                  componentTitle={t("user")}
                  component={
                    <div className="">
                      <Button
                        colorScheme="black"
                        size="sm"
                        label={
                          <div className="flex flex-initial items-center justify-center">
                            <Invite color="fill-white" size={18} />
                            <span className="ml-2 lg:ml-0 text-xs">
                              {t("inviteUser") as string}
                            </span>
                          </div>
                        }
                        onClick={toggleAssignUserModal}
                      />
                    </div>
                  }
                />
              )}
            </div>

            <div className="flex flex-col flex-1 mt-3">
              {terminal?.device?.id ? (
                <DeviceListItem
                  title={t("device")}
                  role={"client"}
                  disconnectDevice={() => {
                    UnAssignTerminal(UnAssignEnum.device);
                  }}
                  item={{ ...terminal?.device }}
                />
              ) : (
                <EmptyState
                  title={t("itemNotFound")}
                  componentTitle={t("device")}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:flex justify-between gap-4 mt-4">
            <div className="flex flex-col flex-1">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                {t("transactions")}
              </div>
              {terminal?.reference_terminal_id && (
                <ListTransactions
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshTransactions}
                  setIsLoading={setTransactionsIsLoading}
                />
              )}
            </div>
            <div className="flex flex-col flex-1">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                {t("reconciliations")}
              </div>
              {terminal?.reference_terminal_id && (
                <ListReconciliations
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshReconciliations}
                  setRefresh={setRefreshReconciliations}
                  setIsLoading={setReconciliationsIsLoading}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col flex-1 mt-3">
            <div className="flex justify-between">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                {t("profile")}
              </div>
              <div className="flex gap-2">
                <div>
                  <Button
                    colorScheme={"skyblue"}
                    label={
                      <div className="flex items-center gap-2">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="20"
                            height="20"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
                          </svg>
                        </div>
                        <div className="flex-auto">
                          {t("partialFileDownload")}
                        </div>
                      </div>
                    }
                    onClick={() => fileDownload(false)}
                  ></Button>
                </div>
                <div>
                  <Button
                    colorScheme={"lightYellow"}
                    label={
                      <div className="flex items-center gap-2">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="20"
                            height="20"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
                          </svg>
                        </div>
                        <div className="flex-auto">{t("fullFileDownload")}</div>
                      </div>
                    }
                    onClick={() => fileDownload(true)}
                  ></Button>
                </div>
              </div>
            </div>
            {terminal?.profile?.id ? (
              <Card>
                <div className="flex justify-between items-center ltr">
                  <div className="flex gap-10">
                    <div className="flex flex-col">
                      <div>{t("profileDate")}</div>
                      <div className="flex gap-2">
                        <div>
                          {moment(terminal.profile.updated_at).format(
                            "YYYY-MM-DD"
                          )}
                          <b>
                            {" "}
                            {moment(terminal.profile.updated_at).format(
                              "hh:mm:ss A"
                            )}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>{t("createdBy")}</div>
                      <div className="font-bold">Host</div>
                    </div>
                  </div>
                  {/* <div className="rounded bg-blue-500 p-2 text-white">
                    {t("current")}
                  </div> */}
                </div>
              </Card>
            ) : (
              <EmptyState title={t("itemNotFound")} />
            )}
          </div>
        </div>
      </div>
      {terminal?.id ? (
        <AssignTerminalToUserModal
          isOpen={isAssignToUserModalOpen}
          toggleModal={toggleAssignUserModal}
          terminalId={terminal?.id}
          refresh={() => setRefreshTerminal(!refreshTerminal)}
          merchant_id={terminal.merchant.id}
        />
      ) : null}
    </>
  );
};

export default ViewTerminal;
