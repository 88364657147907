import React, { ChangeEvent, ElementType, FC } from "react";
import { useTranslation } from "react-i18next";
import { Errors } from "./error";
import { Feedback } from "./feedback";

interface InputType {
  label?: string;
  name: string;
  value: string;
  onChange: (e: string, event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  icon?: ElementType;
  type?: "text" | "email" | "search" | "password";
  prefix?: string;
  inputContainerClasses?: string;
  inputLabelClasses?: string;
  inputClasses?: string;
  feedback?: {
    message: string;
    colorScheme: "green" | "red";
  };
  errors?: string[];
}

export const Input: FC<InputType> = ({
  label,
  name,
  type = "text",
  value,
  placeholder,
  onChange,
  isDisabled = false,
  icon,
  prefix,
  feedback,
  inputClasses = "",
  inputLabelClasses = "",
  inputContainerClasses = "",
  errors = [],
}) => {
  const { i18n } = useTranslation();
  return (
    <div className={"flex flex-col my-2 " + inputContainerClasses}>
      {label ? (
        <label
          className={
            i18n.language == "ar"
              ? "text-sm lg:text-base font-bold mb-1 text-black text-right" +
                inputLabelClasses
              : "text-sm lg:text-base font-bold mb-1 text-black " +
                inputLabelClasses
          }
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}
      {prefix ? (
        <InputWPrefix
          prefix={prefix}
          name={name}
          type={type}
          inputClasses={inputClasses}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          isDisabled={isDisabled}
        />
      ) : icon ? (
        <InputWIcon
          icon={icon}
          name={name}
          type={type}
          inputClasses={inputClasses}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          isDisabled={isDisabled}
        />
      ) : (
        <DefaultInput
          isDisabled={isDisabled}
          name={name}
          type={type}
          value={value}
          inputClasses={inputClasses}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
      {feedback?.message && (
        <div className="block">
          <Feedback
            message={feedback?.message}
            colorScheme={feedback?.colorScheme}
          />
        </div>
      )}
      <Errors errors={errors} />
    </div>
  );
};

const DefaultInput = ({
  name,
  type,
  value,
  onChange,
  placeholder,
  inputClasses,
  isDisabled,
}: Partial<InputType>) => {
  return (
    <input
      id={name}
      name={name}
      type={type}
      value={value}
      disabled={isDisabled}
      onChange={(e) => onChange && onChange(e.target.value, e)}
      placeholder={placeholder}
      className={
        "rounded-md border-gray-200 font-bold text-sm lg:text-base focus:border-primary focus:ring-primary focus:ring-1 " +
        inputClasses
      }
    />
  );
};

const InputWPrefix = ({
  prefix,
  name,
  type,
  inputClasses,
  placeholder,
  onChange,
  value,
  isDisabled,
}: Partial<InputType>) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm z-0">
      <div className="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none border-r border-r-6 border-gray-300 font-bold">
        <span className="sm:pr-1 text-sm lg:text-base">{prefix} </span>
      </div>
      <input
        type={type}
        name={name}
        id={name}
        className={
          "block w-full pl-20 sm:pl-20 border-gray-200 rounded-md font-bold text-sm lg:text-base focus:border-primary focus:ring-primary focus:ring-1  " +
          inputClasses
        }
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};

const InputWIcon = ({
  icon: Icon,
  name,
  type,
  inputClasses,
  placeholder,
  onChange,
  value,
  isDisabled,
}: Partial<InputType>) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none">
        {Icon && <Icon size={16} color="fill-gray-300" />}
      </div>
      <input
        type={type}
        name={name}
        id={name}
        className={
          "block w-full pl-10 border-gray-300 rounded-md border-gray-200 rounded-md pl-1 font-bold text-sm lg:text-base focus:border-primary focus:ring-primary focus:ring-1 " +
          inputClasses
        }
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};
