import React, { FC, ReactElement } from "react";
import { mergeClassNames } from "../utils";

interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: any;
  label?: string | ReactElement;
  icon?: ReactElement;
  size?: "sm" | "lg";
  isRounded?: boolean;
  colorScheme?:
    | "blue"
    | "gray"
    | "default"
    | "indigo"
    | "disabled"
    | "red"
    | "black"
    | "skyblue"
    | "lightYellow"
    | "primary";
  isDisabled?: boolean;
  classNames?: string;
}

const colorSchemeClasses: { [key: string]: string } = {
  blue: "text-white bg-blue-500 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
  gray: "text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400",
  default:
    "text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 border border-gray-300 shadow ",
  indigo:
    "text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-100 border border-indigo-300 shadow ",
  disabled:
    "bg-opacity-50 cursor-not-allowed bg-gray-100 hover:bg-gray-100 hover:bg-opacity-50 shadow-none",
  red: "text-red-600 bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-100 border border-red-200 shadow",
  black:
    "text-white bg-gray-900 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 border shadow",
  skyblue:
    "text-black bg-blue-200 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 border border-blue-200 shadow",
  lightYellow:
    "text-black bg-yellow-200 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-200 border border-yellow-200 shadow",
  primary:
    "text-secondary bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary border border-primary shadow",
};

const sizeClasses: { sm: string; lg: string } = {
  sm: "px-4 py-1 text-xs",
  lg: "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm",
};

export const Button: FC<ButtonType> = ({
  label,
  onClick,
  colorScheme,
  style,
  isRounded = true,
  icon,
  size = "lg",
  classNames = "",
  isDisabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      style={style}
      disabled={isDisabled}
      className={mergeClassNames(
        "w-full my-2 border border-transparent font-bold shadow-sm text-center flex flex-row items-center gap-2 justify-center",
        colorScheme ? colorSchemeClasses[colorScheme] : "",
        classNames,
        isRounded ? "rounded-full" : "rounded-md",
        sizeClasses[size],
        isDisabled ? colorSchemeClasses["disabled"] : ""
      )}
    >
      {icon ? icon : null}
      {label}
    </button>
  );
};
