import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../forms";
import { Android } from "../icons";
import { Card } from "../shared";
import { Badge, BadgeType } from "../shared/badge";
import { DeviceListItemType } from "../types/device";
import { isObjectEmpty } from "../utils";
import { EmptyState } from "./empty-state";

export const DeviceListItem: FC<DeviceListItemType> = ({
  role,
  onClick,
  title,
  disconnectDevice,
  item,
}) => {
  const { t, i18n } = useTranslation();
  const deviceBadge: BadgeType = item?.is_active
    ? { label: t("active") }
    : { label: t("notActive"), color: "red" };
  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full" onClick={onClick}>
        <div className="flex flex-row items-center">
          <Android containerClassNames="pr-4" />
          <div className="flex flex-col flex-1">
            <div className="flex flex-row gap-2 lg:my-1">
              <span className="font-bold text-sm lg:text-base">
                {item?.brand as string}
              </span>
              <div className="hidden lg:flex">
                <Badge {...deviceBadge} isRounded={true} size="sm" />
              </div>
            </div>
            <span className="text-gray-600 text-xs">
              {item?.model as string}
            </span>
          </div>
          {disconnectDevice && (
            <div className="flex-initial">
              <Button
                onClick={() => disconnectDevice(item?.id)}
                label={t("disconnectDevice") as string}
                colorScheme="red"
                size="sm"
                isRounded={false}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
