import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import Api, { ApiParams, refreshApi } from ".";

interface Login extends ApiParams {
  email: string;
  password: string;
  recaptchaToken: string;
}
type RestPassword = {
  email: string;
  recaptchaToken: string;
};
type UpdatePassword = {
  password_hash: string;
  password: string;
  recaptchaToken: string;
};

export const loginApi = async ({ email, password, recaptchaToken }: Login) => {
  try {
    const response = await trackPromise(
      Api({
        headers: { recaptchaToken },
        method: "post",
        url: "login",
        data: {
          email,
          password,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const refreshAccessTokenApi = async (refreshToken: string) => {
  const response = await trackPromise(
    refreshApi({
      method: "post",
      url: "refresh-access-token",
      data: {
        refreshToken,
      },
    })
  );
  return await response.data;
};

export const getUserByHashApi = async ({
  passwordHash,
}: {
  passwordHash: string;
}) => {
  try {
    const response = await Api({
      method: "get",
      url: "/get-user-by-hash",
      params: {
        password_hash: passwordHash,
      },
    });
    return response.data as { email: string };
  } catch (e) {
    throw e;
  }
};

export const resetPasswordApi = async ({
  email,
  recaptchaToken,
}: RestPassword) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        headers: { recaptchaToken },
        url: "/reset-password/send-mail",
        data: {
          email,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const updatePasswordApi = async ({
  password_hash,
  password,
  recaptchaToken,
}: UpdatePassword) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "/reset-password/update",
        headers: { recaptchaToken },
        data: {
          password_hash,
          password,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};
