import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Input, UserIcon } from "../../lib";
import { Modal } from "../../lib/modal";
import { initialAdminState } from "../../pages/Admins";
import { createAdminApi } from "../../utils/api/admins-apis";

export type NewAdmin = {
  name: string;
  mobile: string;
  email: string;
  type: string;
  bank_id: string;
};

type Props = {
  toggleModal: any;
  isOpen: any;
  newAdmin: NewAdmin;
  setNewAdmin: React.Dispatch<React.SetStateAction<NewAdmin>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateAdminModal = ({
  toggleModal,
  isOpen,
  newAdmin,
  setNewAdmin,
  setRefresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createAdminErrors, setCreateAdminErrors] = useState<{
    [key: string]: string[];
  }>({});

  const saveAdmin = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setIsLoading(true);
      event.preventDefault();
      await createAdminApi(newAdmin);
      setIsLoading(false);
      close();
    } catch (e: any) {
      setCreateAdminErrors({ ...e?.message });
      setIsLoading(false);
    }
  };

  const close = () => {
    setCreateAdminErrors({});
    setNewAdmin(initialAdminState);
    toggleModal();
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t("addNewAdmin")}
      subTitle={t("admin")}
      close={close}
      icon={UserIcon}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form>
        <Input
          name="name"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({ ...prevState, name: e }))
          }
          value={newAdmin.name}
          errors={createAdminErrors?.name}
          label={t("name")}
        />
        <Input
          name="email"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({ ...prevState, email: e }))
          }
          errors={createAdminErrors?.email}
          value={newAdmin.email}
          label={t("email")}
        />
        <Input
          name="mobile"
          onChange={(e) =>
            setNewAdmin((prevState: NewAdmin) => ({ ...prevState, mobile: e }))
          }
          errors={createAdminErrors?.mobile}
          value={newAdmin.mobile}
          label={t("mobile")}
          prefix="+966"
        />
        <div className="mt-6">
          <Button
            label={t("invite") as string}
            onClick={saveAdmin}
            colorScheme="blue"
          />
        </div>
      </form>
    </Modal>
  );
};

export default CreateAdminModal;
