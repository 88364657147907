import { FC } from "react";
import Content from "../layout/content";

type Props = {};

const Home: FC<Props> = ({}: Props) => {
  return (
    <Content>
      <span></span>
    </Content>
  );
};

export default Home;
