import { FC } from "react";
import { BarLoader } from "react-spinners";

type Props = {
  isLoading: boolean;
  isTransparent?: boolean;
};

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

const LoadingSpinner: FC<Props> = ({
  isLoading,
  isTransparent = false,
}: Props) => {
  return isLoading ? (
    <div
      className={classNames(
        "bg-white flex items-center justify-center rounded-lg absolute top-0 left-0 right-0 bottom-0 z-10",
        isTransparent ? " bg-opacity-50" : ""
      )}
    >
      <BarLoader
        height={4}
        width={140}
        loading={true}
        color="#2564eb"
        css={`
          display: block;
        `}
      />
    </div>
  ) : null;
};

export default LoadingSpinner;
