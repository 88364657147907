import { FC, SetStateAction, useState } from "react";
import {
  TerminalIcon,
  Button,
  Card,
  Select,
  Paginator,
  mergeClassNames,
  Input,
  SearchIcon,
  Errors,
} from "../../lib";
import { Modal } from "../../lib/modal";
import { MerchantResponse } from "../../utils/api/merchants-apis";
import { createTerminalApi } from "../../utils/api/terminals-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { ListTRSMResponseApi } from "../../utils/api/trsm-apis";
import { Feedback } from "../../lib/forms/feedback";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  merchants: MerchantResponse[];
  trsms: ListTRSMResponseApi;
  refreshList: () => void;
  fetchTRSMs: ({}: { page?: number; search?: string }) => void;
  setTrsms: React.Dispatch<React.SetStateAction<ListTRSMResponseApi>>;
};

const ManageTerminals: FC<Props> = ({
  isOpen,
  toggleModal,
  merchants = [],
  trsms,
  setTrsms,
  refreshList,
  fetchTRSMs,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedTRSMs, setSelectedTRSMs] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [registerTerminalFeedback, setRegsiterTerminalFeedback] = useState<any>(
    {}
  );

  const getTRSMs = (e: any) => {
    e.preventDefault();
    fetchTRSMs({ search });
  };

  const selectTRSM = (trsm: string) => {
    if (selectedTRSMs.find((i) => i == trsm)) {
      setSelectedTRSMs((prevState: string[]) =>
        selectedTRSMs.filter((i) => i != trsm)
      );
    } else {
      setSelectedTRSMs((prevState: string[]) => [...prevState, trsm]);
    }
  };
  const merchantOnChange = (merchantId: string) => {
    setSelectedMerchant(merchantId);
    setSelectedTRSMs([]);
  };

  const submit = async () => {
    try {
      setRegsiterTerminalFeedback({});
      setIsLoading(true);
      let response = await createTerminalApi({
        terminal_ids: selectedTRSMs,
        merchant_id: selectedMerchant,
      });
      setRegsiterTerminalFeedback(response.terminals);
      setIsLoading(false);
      if (
        !Object.keys(response.terminals).some(
          (i: any) => response.terminals[i].error
        )
      ) {
        setTimeout(() => {
          refreshList();
          close();
        }, 3000);
      }
    } catch (e: any) {
      setIsLoading(false);
      console.log({ e });
      setRegsiterTerminalFeedback(e);
    }
  };
  const close = () => {
    setRegsiterTerminalFeedback({});
    fetchTRSMs({});
    setSearch("");
    setSelectedTRSMs([]);
    setSelectedMerchant((prevState: string) => "");
    setTrsms({} as ListTRSMResponseApi);
    setIsLoading(false);
    toggleModal();
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      icon={TerminalIcon}
      subTitle={t("terminal")}
      title={t("addNewTerminal")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={false} />
      <Errors errors={registerTerminalFeedback?.general} />
      <div className="flex flex-col">
        <Select
          data={merchants as any[]}
          value={selectedMerchant}
          label={t("merchant")}
          errors={registerTerminalFeedback?.merchant_id}
          name="merchant-id"
          onChange={(merchantId) => merchantOnChange(merchantId)}
          valueKey="id"
          displayKey="name"
        />
      </div>
      {/** search bar gose here ! */}
      {selectedMerchant && (
        <>
          <div className="py-4">
            <hr />
          </div>
          <span className="text-sm lg:text-base font-bold mb-1 text-black block">
            TRSM List
          </span>
          <form className="flex justify-between items-center gap-2">
            <Input
              name="search"
              type="search"
              placeholder="search"
              value={search}
              onChange={setSearch}
              icon={SearchIcon}
              label=""
              inputContainerClasses="w-full"
              isDisabled={selectedMerchant ? false : true}
            />
            <div className="w-10 h-10 flex justify-center items-center rounded-md">
              <Button
                isDisabled={selectedMerchant ? false : true}
                colorScheme="default"
                onClick={getTRSMs}
                isRounded={false}
                classNames={" h-full rounded-md"}
                icon={<SearchIcon />}
              />
            </div>
          </form>
          {(trsms?.trsms || []).map((item, index) => {
            return (
              <div key={index}>
                <Card
                  onClick={() => selectTRSM(item.code)}
                  classNames={mergeClassNames(
                    "w-full my-2 px-4 py-3 flex justify-between items-center font-bold hover:bg-gray-100 cursor-pointer",
                    selectedTRSMs.find((i) => i == item.code)
                      ? "bg-blue-500 border-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white"
                      : "text-gray-700"
                  )}
                >
                  <span className="block">{item.code}</span>
                  <span className="block text-gray-400 text-sm">
                    {item.vendor?.name}
                  </span>
                </Card>

                {registerTerminalFeedback[item.code] &&
                  (registerTerminalFeedback[item.code].error ? (
                    <Errors
                      errors={[
                        registerTerminalFeedback[item.code].error.english,
                      ]}
                    />
                  ) : (
                    <Feedback
                      colorScheme="green"
                      message={registerTerminalFeedback[item.code].message}
                    />
                  ))}

                {/* {registerTerminalFeedback[item.code]. ? {}
                  <Feedback colorScheme="green" message="Hello" />
                ) : // <Errors
                //   errors={[registerTerminalFeedback[item.code].message]}
                // />
                null} */}
              </div>
            );
          })}
          {trsms.pages > 1 ? (
            <div className="w-full flex justify-end">
              <Paginator
                pages={trsms.pages}
                selectPage={(page) => fetchTRSMs({ page, search })}
                size="sm"
              />
            </div>
          ) : null}
          <Errors errors={registerTerminalFeedback?.terminal_ids || []} />
        </>
      )}
      <div className="my-4">
        <Button
          label={t("save") as string}
          onClick={submit}
          colorScheme="blue"
        />
      </div>
    </Modal>
  );
};

export default ManageTerminals;
