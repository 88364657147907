import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listTerminalsApi } from "../../utils/api/terminals-apis";
import { createFilterObject } from "../filters";
import { TerminalsFilters } from "../filters/terminals-filters";
import { EmptyState, GroupedList, TerminalListItem } from "../../lib";
import { useTranslation } from "react-i18next";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  merchantId?: string;
  setIsLoading?: any;
  onRowClick?: (payload: any) => void;
  activeTab?: string;
  limit?: number;
};

const ListTerminals: FC<Props> = ({
  refresh,
  setRefresh,
  merchantId,
  onRowClick,
  setIsLoading,
  activeTab = "all",
  limit = 30,
}: Props) => {
  const [terminals, setTerminals] = useState<any>([]);
  const [pages, setPages] = useState(1);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchTerminals = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTerminalsApi({
        page,
        filter: createFilterObject(activeTab, TerminalsFilters),
        merchantId,
        limit,
      });
      setPages(response.pages);
      setTerminals(response.terminals);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTerminal = (terminal: any) => navigate("/terminals/" + terminal.id);

  useEffect(() => {
    fetchTerminals();
  }, [refresh, activeTab]);

  return terminals.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchTerminals(page),
      }}
      role={"client"}
      onItemClick={(terminal) => goToTerminal(terminal)}
      data={terminals}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
      component={TerminalListItem}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListTerminals;
