import { useCallback, useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../layout/loading-spinner";
import { Button, Errors, Input } from "../lib/forms";
import { getUserByHashApi, updatePasswordApi } from "../utils/api/auth-apis";

const UpdatePassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [searchParams, _] = useSearchParams();
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [resetPasswordErrors, setResetPasswordErrors] = useState<{
    [key: string]: string[];
  }>({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const updatePassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setResetPasswordErrors({});
      setIsLoading(true);
      e.preventDefault();
      if (password != retypePassword)
        throw { general: ["Password does not match!."] };
      await updatePasswordApi({
        password_hash: searchParams.get("p_hash") as string,
        password,
        recaptchaToken,
      });
      setIsLoading(false);
      navigate("/login");
    } catch (e: any) {
      setResetPasswordErrors(e);
      setIsLoading(false);
    }
  };
  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
    setIsLoading(false);
  }, []);

  const getUserByHash = async () => {
    try {
      const { email } = await getUserByHashApi({
        passwordHash: searchParams.get("p_hash") as string,
      });
      setEmail(email);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // navigate("/login");
    }
  };

  useEffect(() => {
    getUserByHash();
  }, []);

  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center login-page">
      <GoogleReCaptcha onVerify={onVerify} />
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <div className="bg-white pt-8 pb-6 px-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md shadow rounded-lg">
        <span className="text-xl font-bold">{t("resetPassword")}</span>
        <form className="mt-4 mb-3 flex flex-col">
          <Errors errors={resetPasswordErrors?.general || []} />
          <Input
            name="email"
            value={email}
            label={t("email")}
            onChange={() => {}}
            isDisabled={true}
          />
          <Input
            name="password"
            value={password}
            type="password"
            label={t("password") as string}
            errors={resetPasswordErrors?.password || []}
            onChange={(e) => {
              setPassword(e);
            }}
          />
          <Input
            name="retype-password"
            value={retypePassword}
            type="password"
            label={t("retypePassword") as string}
            errors={resetPasswordErrors?.retypePassword || []}
            onChange={(e) => {
              setRetypePassword(e);
            }}
          />
          <Button
            label={t("resetPassword") as string}
            onClick={updatePassword}
            classNames="w-full py-2 px-4 mt-4"
            colorScheme={"blue"}
          />
        </form>
      </div>
    </div>
  );
};
export default UpdatePassword;
