import Api, { List } from ".";
import { dateRange } from "../../components/query-builder/date-picker";

interface TRSMs extends List {
  is_not_assigned?: boolean;
  search?: string;
}

interface TRSM {
  id: string;
}

interface CreateTRSM {
  trsm: string;
}

export interface TRSMResponseApi {
  trsm: {
    id: number;
    client_id: number;
    trsm: number;
  };
}

export interface ListTRSMResponseApi {
  trsms: [
    {
      id: number;
      client_id: number;
      code: string;
      vendor: {
        id: number;
        name: string;
      };
    }
  ];
  pages: number;
}

export const listTRSMsApi = async ({
  limit = 30,
  page = 1,
  is_not_assigned,
  search,
}: TRSMs) => {
  try {
    const response = await Api({
      method: "get",
      url: "trsms",
      params: {
        limit,
        page,
        is_not_assigned,
        search,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const findTRSMApi = async ({ id }: TRSM) => {
  try {
    const response = await Api({
      method: "get",
      url: "trsms/" + id,
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const createTRSMApi = async ({ trsm }: CreateTRSM) => {
  try {
    const response = await Api({
      method: "post",
      url: "trsm",
      data: {
        trsm_code: trsm,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e?.message ? e.message : e;
  }
};

export const terminalsCreateAndRegister = async (body: any) => {
  try {
    const response = await Api({
      method: "post",
      url: "terminalsCreateAndRegister/",
      data: {
        ...body,
      },
    });
  } catch (e: any) {
    throw e?.message ? e.message : e;
  }
};

export const trsmsUpload = async (body: any) => {
  try {
    const response = await Api({
      method: "post",
      url: "trsms-upload/",
      data: {
        ...body,
      },
    });
  } catch (e: any) {
    throw e?.message ? e.message : e;
  }
};
