import { FC } from "react";
import { ArrowLeft, ArrowRight, Tick } from "../icons";
import { Card } from "../shared";
import { default as Mada } from "../icons/mada.png";
import { default as Visa } from "../icons/visa.png";
import { default as Mastercard } from "../icons/mastercard.png";
import { default as American_Express } from "../icons/American_Express.png";
import { default as GCCNET } from "../icons/GCCNET.png";
import { default as Discover } from "../icons/Discover.png";
import { default as UnionPay } from "../icons/UnionPay.png";
import { default as Maestro } from "../icons/Maestro.png";
import { default as JCB } from "../icons/JCB.png";
import { formatPrice } from "../utils";
import { TransactionListItemType } from "../types/transaction";
import { useTranslation } from "react-i18next";

const PaymentSchemeIcons: { [key: string]: string } = {
  P1: Mada,
  VC: Visa,
  MC: Mastercard,
  DM: Maestro,
  AX: American_Express,
  JC: JCB,
  DC: Discover,
  UP: UnionPay,
  GN: GCCNET,
};

export const TransactionListItem: FC<TransactionListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  //default is xs - to sm -> we reach 600
  const { i18n, t } = useTranslation();
  return (
    <div className="mt-3 overflow-hidden">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full " onClick={onClick}>
        <div className="grid grid-cols-4 sm:grid-cols-5 lg:grid-cols-5 items-center justify-between">
          <div
            className={`${
              i18n.language == "ar" ? "pr-0" : "pl-0"
            } flex items-center col-span-2`}
          >
            <img
              src={PaymentSchemeIcons[item?.card_scheme_id]}
              className="w-7 lg:w-10 "
            />
            <div className="hidden xl:flex flex-col px-4 ">
              <span className="flex-1 font-bold">
                {item.receipts?.[0]?.pan}
              </span>
              <span className="text-gray-600 text-xs">
                {item.receipts?.[0]?.start_time}
              </span>
            </div>
          </div>
          <div
            className={
              i18n.language == "ar"
                ? "flex flex-col md:flex-initial lg:pl-8 "
                : "flex flex-col md:flex-initial lg:pr-8 "
            }
          >
            <span className="text-sm lg:text-base flex-1 font-bold">
              {item.transaction_type
                ? i18n.language == "ar"
                  ? item.transaction_type?.name?.arabic
                  : item.transaction_type?.name?.english
                : i18n.language == "ar"
                ? item.receipts[0].transaction_type?.name?.arabic
                : item.receipts[0].transaction_type?.name?.english}
              {}
            </span>
            <span className="flex-1 text-gray-600 text-xs">
              {"#" + item.receipts?.[0]?.retrieval_reference_number}
            </span>
          </div>
          <div className="h-full hidden flex-col md:hidden lg:flex sm:flex justify-center px-1 items-center gap-1 ">
            {item?.is_reconcilied ? (
              <div className="px-2 rounded bg-green-100 text-green-800 w-full text-center ">
                {t("reconciled") as string}
              </div>
            ) : (
              <div className="px-2 rounded bg-yellow-100 text-yellow-800 w-full text-center ">
                {t("notReconciled") as string}
              </div>
            )}
            {item?.receipts?.[0].is_reversed ? (
              <div className="px-2 rounded bg-red-100 text-red-800 w-full text-center ">
                {t("reversed") as string}
              </div>
            ) : undefined}
          </div>

          <div className=" flex gap-2 h-full items-center justify-end">
            <span className="font-bold">
              {formatPrice(
                item.amount_authorized?.value
                  ? item.amount_authorized?.value
                  : item.receipts?.[0]?.amount_authorized?.value,
                i18n.language == "ar"
                  ? item.receipts?.[0]?.currency?.arabic
                  : item.receipts?.[0]?.currency?.english
              )}
            </span>
            {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
          </div>
        </div>
      </Card>
    </div>
  );
};
