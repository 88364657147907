import React, { FC, PropsWithChildren } from "react";
import { mergeClassNames } from "../utils";

export interface CardType {
  classNames?: string;
  onClick?: (item?: any) => void;
}

export const Card: FC<PropsWithChildren<CardType>> = ({
  classNames = "",
  children,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={mergeClassNames(
        "border-2 border-gray-300 shadow-sm rounded-md py-2 px-2",
        classNames,
        onClick ? "cursor-pointer hover:shadow-md" : ""
      )}
    >
      {children}
    </div>
  );
};
