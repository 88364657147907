import { FC, createContext, ReactElement, useEffect, useState } from "react";

type Props = {
  children: ReactElement;
};

type MasterDataType = {
  vendors: { [key: string]: any }[];
};

const masterDataInitialState: MasterDataType = {
  vendors: [],
};

const MasterDataContext = createContext(masterDataInitialState);

export const MasterDataContextProvider: FC<Props> = ({ children }: Props) => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    // fetchVendors();
  }, []);

  return (
    <MasterDataContext.Provider
      value={{
        vendors,
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataContext;
