import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList } from "../../lib";
import { UserListItem } from "../../lib/list-item/user-list";
import { listUsersApi } from "../../utils/api/users-apis";
import { createFilterObject, UsersFilters } from "../filters";

type Props = {
  limit?: number;
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  merchantId?: string;
  setIsLoading?: any;
  activeTab?: string;
  revokeAccess?: (user: any) => void;
};

const ListUsers: FC<Props> = ({
  refresh,
  setRefresh,
  merchantId,
  setIsLoading,
  activeTab,
  limit = 10,
  revokeAccess,
}: Props) => {
  const [users, setUsers] = useState<any>([]);
  const [pages, setPages] = useState(1);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchUsers = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listUsersApi({
        page,
        filter: createFilterObject(activeTab ? activeTab : "all", UsersFilters),
        merchantId,
        limit,
      });
      setPages(response.pages);
      setUsers(response.users);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [refresh, activeTab]);

  return users.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchUsers(page),
      }}
      role={"client"}
      data={users}
      component={UserListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
        order: "desc",
      }}
      //revokeAccess={(user) => revokeAccess?.(user)}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListUsers;
