import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import UserContext, { UserContextType } from "../contexts/UserContext";
import { availableLanguages } from "../i18n";

type Props = {};
type NavbarItem = {
  name: string;
  path: string;
};
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const NavBar = ({}: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout, toggleModal } = useContext(UserContext) as UserContextType;
  const { t, i18n } = useTranslation();
  const navigation: NavbarItem[] = [
    { name: t("merchants"), path: "/merchants" },
    { name: t("terminals"), path: "/terminals" },
    { name: t("TRSMs"), path: "/trsms" },
    { name: t("transactions"), path: "/transactions" },
    {
      name: t("reconciliations"),
      path: "/reconciliations",
    },
    { name: t("admins"), path: "/admins" },
    { name: t("users"), path: "/users" },
  ];

  const switchClient = () => {
    setSidebarOpen(false);
    toggleModal();
  };

  const logoutAndRedirect = () => {
    setSidebarOpen(false);
    logout();
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <span>NearPay</span>
                </div>

                <div className="mt-5 flex-1 h-0 overflow-y-auto ">
                  <nav className="px-2 gap-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.path}
                        onClick={() => setSidebarOpen(false)}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group rounded-md py-2 px-2 flex items-center text-base font-medium"
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                    <hr />
                    {/**FIXME */}
                    <select
                      defaultValue={i18n.language}
                      onChange={(e) => i18n.changeLanguage(e.target.value)}
                    >
                      {availableLanguages.map((language) => (
                        <option key={language}>{language}</option>
                      ))}
                    </select>
                    <button
                      onClick={switchClient}
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                    >
                      Switch Client
                    </button>
                    <button
                      onClick={logoutAndRedirect}
                      className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                    >
                      Logout
                    </button>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="w-full flex bg-white px-4 shadow-md">
        <button
          onClick={() => setSidebarOpen(true)}
          className="lg:hidden p-2 my-2 text-sm text-gray-500 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden lg:flex">
          {navigation.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) =>
                classNames(isActive ? "border-b-4 border-blue-600" : "", "p-4 ")
              }
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default NavBar;
