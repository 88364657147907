import { useCallback, useState } from "react";
import { Button, Input, Errors } from "../lib/forms";
import { resetPasswordApi } from "../utils/api/auth-apis";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

type Props = {};
const ResetPassword = ({}: Props) => {
  const [email, setEmail] = useState(process.env.REACT_APP_TESTING_EMAIL || "");
  const [resetPasswordErrors, setResetPasswordErrors] = useState<{
    [key: string]: any;
  }>({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const resetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      setResetPasswordErrors({});
      await resetPasswordApi({ email, recaptchaToken });
      navigate("/message-sent");
    } catch (e: any) {
      console.log(e);
      setResetPasswordErrors(e);
    }
  };

  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center login-page">
      <GoogleReCaptcha onVerify={onVerify} />
      <div className="bg-white pt-8 pb-6 px-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md shadow rounded-lg">
        <span className="text-xl font-bold">{t("resetPassword")}</span>
        <div className="mb-4 mt-2">
          <Errors
            errors={
              resetPasswordErrors.error
                ? [
                    i18n.language
                      ? resetPasswordErrors.error.arabic
                      : resetPasswordErrors.error.english,
                  ]
                : []
            }
          />
        </div>
        <form className="mt-4 mb-3 flex flex-col">
          <Input
            name="email"
            value={email}
            type="email"
            label={t("email")}
            onChange={setEmail}
            errors={resetPasswordErrors?.email as []}
          />
          <Button
            label={t("resetPassword") as string}
            colorScheme="blue"
            onClick={resetPassword}
            classNames="w-full py-2 px-4 mt-4"
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
