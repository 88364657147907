import { FC, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, MerchantIcon } from "../../lib";
import {
  findMerchantApi,
  MerchantResponse,
} from "../../utils/api/merchants-apis";
import {
  initialUserState,
  ManageMerchant,
  ManageMerchantModal,
  ManageUser,
} from "../modal";
import ManageUserModal from "../modal/manage-user";
import ListReconciliations from "../reconciliations/list";
import ListTerminals from "../terminals/list";
import ListTransactions from "../transactions/list";
import ListUsers from "../users/list";

type Props = {
  id: string;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  merchant: Partial<MerchantResponse>;
  setMerchant: React.Dispatch<React.SetStateAction<Partial<MerchantResponse>>>;
};

const ViewMerchant: FC<Props> = ({
  id,
  setIsLoading,
  isLoading,
  merchant,
  setMerchant,
}: Props) => {
  const [isManageUserModalOpen, setIsManageUserModalOpen] = useState(false);
  const [isCreateMerchantModalOpen, setIsCreateMerchantModalOpen] =
    useState(false);
  const [refreshMerchant, setRefreshMerchant] = useState(false);
  const toggleEditMerchantModal = () => {
    setIsCreateMerchantModalOpen((prev) => !prev);
  };
  const [selectedUser, setSelectedUser] =
    useState<ManageUser>(initialUserState);
  const [refreshAdmins, setRefreshAdmins] = useState(false);
  const [refreshTerminals, setRefreshTerminals] = useState(false);
  const [refreshReconciliations, setRefreshReconciliations] = useState(false);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const { t } = useTranslation();

  const revokeAccess = (user: any) => {
    console.log({ user }); //FIXME -- -- -- -- -- -- -- -- -- --
  };

  const findMerchant = async () => {
    try {
      setIsLoading(true);
      const merchant = await findMerchantApi({ id: id as string });
      setMerchant(merchant);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
    }
  };

  const toggleManageUserModal = () => {
    setIsManageUserModalOpen((prev) => !prev);
  };

  useEffect(() => {
    findMerchant();
    setSelectedUser((prevState: ManageUser) => ({
      ...prevState,
      merchantId: String(merchant?.id),
    }));
  }, [id, refreshMerchant]);

  return (
    <div>
      {/* header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="lg:p-4">
            <MerchantIcon size={28} />
          </div>
          <div className="flex flex-col items-start">
            <div className="font-bold text-md lg:text-xl">
              {merchant?.name}{" "}
            </div>
            <div className="text-xs lg:text-sm ltr">{"#" + merchant?.id} </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              toggleEditMerchantModal();
            }}
            colorScheme="black"
            label={t("edit") as string}
            classNames="rounded-full py-2"
          />
        </div>
      </div>
      {/** Main content  */}
      <div className="flex flex-col gap-2 mt-4 lg:mt-0">
        {/** Terminals starts here !!  */}
        <div className="flex flex-col ">
          <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold">
            {t("terminals")}
          </div>
          <ListTerminals
            limit={5}
            refresh={refreshTerminals}
            setRefresh={setRefreshTerminals}
            setIsLoading={setIsLoading}
            merchantId={id}
          />
        </div>
        <div className="grid grid-cols-1 lg:flex justify-between gap-4">
          <div className="flex flex-col flex-1">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("transactions")}
            </div>
            <ListTransactions
              merchantId={id}
              limit={5}
              refresh={refreshTransactions}
              setIsLoading={setIsLoading}
            />
          </div>
          <div className="flex flex-col flex-1">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("reconciliations")}
            </div>
            <ListReconciliations
              merchantId={id}
              limit={5}
              refresh={refreshReconciliations}
              setRefresh={setRefreshReconciliations}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
        {/* admin section !! */}
        <div className="flex flex-col ">
          <div className="flex justify-between">
            <div className="text-lg lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
              {t("merchantAdmins")}
            </div>
            <div className="w-30 h-30">
              <Button
                label={t("addNewUser") as string}
                onClick={() => {
                  toggleManageUserModal();
                }}
                colorScheme={"skyblue"}
              />
            </div>
          </div>
          <ListUsers
            refresh={refreshAdmins}
            setRefresh={setRefreshAdmins}
            activeTab={"all"}
            setIsLoading={setIsLoading}
            merchantId={id}
            limit={5}
            revokeAccess={(user) => revokeAccess(user)}
          />
        </div>
      </div>

      <ManageUserModal
        isOpen={isManageUserModalOpen}
        toggleModal={toggleManageUserModal}
        merchantId={merchant?.id}
        user={selectedUser}
        setUser={setSelectedUser}
        setRefreshUsers={setRefreshAdmins}
      />
      <ManageMerchantModal
        isOpen={isCreateMerchantModalOpen}
        toggleModal={toggleEditMerchantModal}
        newMerchant={merchant as ManageMerchant}
        setNewMerchant={
          setMerchant as React.Dispatch<SetStateAction<ManageMerchant>>
        }
        refreshMerchant={setRefreshMerchant}
      />
    </div>
  );
};

export default ViewMerchant;
