import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../lib";

const ResetPasswordMessageSent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center login-page">
      <div className="bg-white pt-8 pb-6 px-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md shadow rounded-lg flex flex-col">
        <span className="text-xl font-bold text-gray-700 p-2">
          {t("resetPassword") as string}
        </span>
        <div className="text-gray-400 mt-3 p-2">
          {t("resetPasswordMessage")}
        </div>
        <Button
          onClick={() => navigate("/login")}
          label={t("login") as string}
          colorScheme="blue"
        />
      </div>
    </div>
  );
};

export default ResetPasswordMessageSent;
