import React, { ChangeEvent, FC, Fragment } from "react";
import { capitalize } from "../utils";
import { Errors } from "./error";

interface SelectType {
  label: string;
  name: string;
  data: Array<{ [key: string]: string }>;
  value: string;
  onChange: (e: string, event: ChangeEvent<HTMLSelectElement>) => void;
  isDisabled?: boolean;
  displayKey?: string;
  valueKey?: string;
  placeholder?: string;
  selectContainerClasses?: string;
  selectLabelClasses?: string;
  selectClasses?: string;
  errors?: string[];
}

export const Select: FC<SelectType> = ({
  label,
  name,
  displayKey = "name",
  valueKey = "value",
  data = [],
  value,
  onChange,
  placeholder,
  isDisabled = false,
  selectContainerClasses = "",
  selectLabelClasses = "",
  selectClasses = "",
  errors = [],
}) => {
  return (
    <div className={"flex flex-col my-2 " + selectContainerClasses}>
      <label
        className={
          "font-bold mb-1 text-black text-sm lg:text-base" + selectLabelClasses
        }
        htmlFor={name}
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        onChange={(e) => onChange(e.target.value, e)}
        className={
          "border-gray-200 rounded-md font-bold text-sm lg:text-base " +
          selectClasses
        }
        value={value}
        disabled={isDisabled}
      >
        <option value="">{placeholder}</option>
        {data.map((item: any, index: number) => (
          <Fragment key={index}>
            <option value={item[valueKey]}>
              {capitalize(item[displayKey])}
            </option>
          </Fragment>
        ))}
      </select>
      <Errors errors={errors} />
    </div>
  );
};
