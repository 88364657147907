import moment from "moment";
import React, { ElementType, FC } from "react";
import { Paginator } from "./paginator";

interface GroupedListType {
  data: { [key: string]: any }[];
  role: "admin" | "client";
  onItemClick?: (item?: any) => void;
  pages?: {
    total: number;
    selectPage: (page: number) => void;
  };
  groupBy: {
    key: string;
    order?: "asc" | "desc";
    isDate: boolean;
  };
  component: ElementType;
  actionButtonOnClick?: (e: Event, item: any) => void;
}

const groupBy = function (xs: any, key: string, isDate: boolean = true) {
  if (isDate)
    xs = xs.map((i: any) => ({
      ...i,
      grouped_key: moment(i[key]).format("YYYY-MM-DD"),
    }));

  return xs.reduce((rv: any, x: any) => {
    (rv[x["grouped_key"]] = rv[x["grouped_key"]] || []).push(x);
    return rv;
  }, {});
};

const orderBy = (order: "asc" | "desc", obj: any) => {
  return order == "desc"
    ? Object.keys(obj).sort().reverse()
    : Object.keys(obj).sort();
};

export const GroupedList: FC<GroupedListType> = ({
  data,
  role,
  onItemClick,
  pages = {
    total: 1,
    selectPage: (page: number) => {},
  },
  groupBy: { key, order = "desc", isDate },
  component: Component,
  actionButtonOnClick,
}) => {
  const list = groupBy(data, key);
  return (
    <div className="flex flex-col">
      {orderBy(order, list).map((key: string, index: number) => {
        return (
          <div key={index} className="w-full block flex-1 my-2 py-2">
            <span className="font-bold lg:text-lg text-sm text-gray-500">
              {isDate ? moment.utc(key).format("YYYY-MM-DD") : key}
            </span>
            <div className="flex flex-col">
              {list[key].map((i: any, index: number) => (
                <Component
                  key={index}
                  role={role}
                  item={i}
                  actionButtonOnClick={actionButtonOnClick}
                  onClick={onItemClick ? () => onItemClick(i) : undefined}
                />
              ))}
            </div>
          </div>
        );
      })}
      {pages && pages.total > 1 ? (
        <div className="w-full flex justify-end items-end">
          <Paginator
            pages={pages.total}
            selectPage={pages.selectPage as (page: number) => void}
          />
        </div>
      ) : null}
    </div>
  );
};
