import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Input, Button } from "../lib/forms";
import UserContext, { UserContextType } from "../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { Errors } from "../lib";
import { loginApi, refreshAccessTokenApi } from "../utils/api/auth-apis";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import LoadingSpinner from "../layout/loading-spinner";
import { useTranslation } from "react-i18next";

const Login: FC<{}> = ({}) => {
  const [email, setEmail] = useState(
    process.env.REACT_APP_TESTING_EMAIL
      ? process.env.REACT_APP_TESTING_EMAIL
      : ""
  );
  const [password, setPassword] = useState(
    process.env.REACT_APP_TESTING_PASSWORD
      ? process.env.REACT_APP_TESTING_PASSWORD
      : ""
  );
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [loginErrors, setLoginErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const { addToken, toggleModal, getUser } = useContext(
    UserContext
  ) as UserContextType;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const onVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    setIsLoading(false);
  }, [executeRecaptcha]);

  useEffect(() => {
    onVerify();
  }, [onVerify]);
  const login = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e?.preventDefault();
      if (!executeRecaptcha)
        return setLoginErrors({
          error: { english: "Recaptcha token is not valid any more !" },
        });
      const token = await executeRecaptcha();
      setRecaptchaToken(token);

      setLoginErrors({});
      let response = await loginApi({
        email,
        password,
        recaptchaToken: recaptchaToken as string,
      });
      addToken(response.accessToken, response.refreshToken);
      const admin = getUser();
      if (admin.clients.length > 1) toggleModal();
      navigate("/merchants");
    } catch (error: any) {
      if (error.message && error.statusCode == 400) {
        setLoginErrors({
          error: {
            english:
              "Invalid recaptcha token. Make sure you are using trusted browser",
            arabic:
              "رمز recaptcha غير صالح. تأكد من أنك تستخدم متصفحًا موثوقًا",
          },
        });
      } else if (error) setLoginErrors(error);
    }
  };

  return (
    <div className="flex-grow w-full h-full flex flex-col items-center justify-center login-page">
      <LoadingSpinner isLoading={isLoading} />
      {/* <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={} /> */}
      <div
        className={
          "bg-white pt-8 pb-6 px-10 mt-8 sm:mx-auto sm:w-full sm:max-w-md shadow rounded-lg"
        }
      >
        <span className="text-xl font-bold">{t("login")}</span>
        <form className="mt-4 mb-3 flex flex-col">
          <div className="mb-4 mt-2">
            <Errors
              errors={
                loginErrors.error
                  ? [
                      i18n.language == "ar"
                        ? loginErrors.error.arabic
                        : loginErrors.error.english,
                    ]
                  : []
              }
            />
          </div>
          <Input
            name="email"
            value={email}
            type="email"
            label={t("email")}
            errors={loginErrors?.email}
            onChange={setEmail}
          />
          <Input
            name="password"
            value={password}
            type="password"
            label={t("password")}
            errors={loginErrors?.password}
            onChange={setPassword}
          />
          <GoogleReCaptcha onVerify={onVerify} />
          <div id="test-login-button">
            <Button
              label={t("login") as string}
              onClick={login}
              classNames="w-full py-2 px-4 mt-4"
              colorScheme="blue"
            />
          </div>
          <div className="pt-4 text-center">
            <Link
              to={"/reset-password"}
              className="text-blue-600 text-sm underline block"
            >
              {t("resetPassword")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
