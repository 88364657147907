import { FC, ReactNode } from "react";
import { formatPrice } from "../utils";
import { ArrowLeft, ArrowRight } from "../icons";
import { Remove } from "../icons";
import { Tick } from "../icons";
import { Card } from "../shared";
import { ReconciliationListItemType } from "../types/reconciliation";
import { formatTime } from "../utils/format-functions";
import { useTranslation } from "react-i18next";

export const ReconciliationListItem: FC<ReconciliationListItemType> = ({
  role,
  onClick,
  item,
}) => {
  const { i18n, t } = useTranslation();
  const statusIcon: { [key: string]: ReactNode } = {
    1: (
      <div className="flex flex-row gap-1 items-center text-green-600 font-bold text-xs lg:text-sm">
        <span>{t("balanced") as string}</span>
        <Tick color="text-green-600" />
      </div>
    ),
    0: (
      <div className="flex flex-row gap-1 items-center text-red-600 font-bold text-xs lg:text-sm">
        <span>{t("notBalanced") as string}</span>
        <Remove color="text-red-600" />
      </div>
    ),
  };
  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full mt-3" onClick={onClick}>
      <div className="flex flex-row items-center">
        <div className="flex flex-col flex-1 text-sm lg:text-base">
          <span className="font-bold">{formatPrice(item?.total_value)}</span>
        </div>
        <div className="hidden lg:flex flex-col flex-1">
          <span className="font-bold">{item?.time}</span>
        </div>
        <div className="flex-initial">
          {statusIcon[Number(item?.is_balanced?.value)]}
        </div>
        <div className="flex-initial">
          {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
        </div>
      </div>
    </Card>
  );
};
